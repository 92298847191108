import { useCallback, useContext, useEffect } from 'react';
import { AppLoaderContainer, StyledAppLoader } from './styles';
import { useIsFetching, useIsMutating } from 'react-query';
import { IsDataLoadingContext } from '../../../context/IsLoading';

export const AppLoader = () => {
  const { isDataLoading, setIsDataLoading } = useContext(IsDataLoadingContext);
  const isLoading = useIsFetching({
    predicate(query) {
      return query.state.status === 'loading'; // get queries that are in hard load state (not refetching in background when data is in cache)
    },
  });
  const isLoadingSearchResults = useIsFetching('GetSearchResults');
  const isMutating = useIsMutating();

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (isLoadingSearchResults === 0 && (isLoading > 0 || isMutating > 0)) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
      }
      setIsDataLoading(true);
    } else {
      timeout = setTimeout(() => {
        setIsDataLoading(false);
      }, 1200);
    }
    return () => {
      clearTimeout(timeout);
      timeout = undefined;
    };
  }, [isLoading, isLoadingSearchResults, isMutating, setIsDataLoading]);

  const handleClick = useCallback((ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    return;
  }, []);

  useEffect(() => {
    const el = document.getElementById('appLoader');
    el?.addEventListener('click', handleClick);
    return () => {
      el?.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <AppLoaderContainer id="appLoader" $show={`${isDataLoading}`} data-cy={`app-loader`}>
      <StyledAppLoader>
        <div className="ldio-ekl1zsv53uh">
          <div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </StyledAppLoader>
    </AppLoaderContainer>
  );
};
