import styled from 'styled-components';
import { ModalBackground } from '../../../Theme/colorsVariables';
import { CenterCenterBox } from '../FlexBox';

export const AppLoaderContainer = styled(CenterCenterBox)<{ $show: string }>`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  display: flex;
  opacity: ${({ $show: show }) => (show === 'true' ? 1 : 0)};
  visibility: ${({ $show: show }) => (show === 'true' ? 'visible' : 'hidden')};
  background: ${ModalBackground};
  transition: opacity 0.3s ease-in-out;
`;

export const StyledAppLoader = styled('div')`
  width: 118px;
  height: 118px;
  display: inline-block;
  overflow: hidden;
  background: none;
  @keyframes ldio-ekl1zsv53uh-1 {
    0% {
      transform: rotate(45deg) scale(0.6) translate(-22.419999999999998px, -22.419999999999998px);
    }
    50% {
      transform: rotate(67.5deg) scale(0.6) translate(-22.419999999999998px, -22.419999999999998px);
    }
    100% {
      transform: rotate(90deg) scale(0.6) translate(-22.419999999999998px, -22.419999999999998px);
    }
  }
  @keyframes ldio-ekl1zsv53uh-2 {
    0% {
      transform: rotate(270deg) scale(0.6) translate(22.419999999999998px, 22.419999999999998px);
    }
    50% {
      transform: rotate(247.5deg) scale(0.6) translate(22.419999999999998px, 22.419999999999998px);
    }
    100% {
      transform: rotate(225deg) scale(0.6) translate(22.419999999999998px, 22.419999999999998px);
    }
  }
  .ldio-ekl1zsv53uh > div {
    transform-origin: 36.58px 36.58px;
    animation: ldio-ekl1zsv53uh-1 0.2s infinite linear;
  }
  .ldio-ekl1zsv53uh > div:nth-child(2) {
    transform-origin: 81.42px 81.42px;
    animation: ldio-ekl1zsv53uh-2 0.2s infinite linear;
    animation-delay: -0.1s;
  }
  .ldio-ekl1zsv53uh div:nth-child(1) > div {
    background: #ff9900;
  }
  .ldio-ekl1zsv53uh div:nth-child(2) > div {
    background: #333333;
  }
  .ldio-ekl1zsv53uh div > div {
    position: absolute;
    width: 16.52px;
    height: 113.28px;
    left: 59px;
    top: 59px;
    transform: translate(-50%, -50%);
  }
  .ldio-ekl1zsv53uh div > div:nth-child(1) {
    width: 89.67999999999999px;
    height: 89.67999999999999px;
    border-radius: 50%;
  }
  .ldio-ekl1zsv53uh div > div:nth-child(6) {
    width: 54.279999999999994px;
    height: 54.279999999999994px;
    background: #f1f2f3;
    border-radius: 50%;
  }
  .ldio-ekl1zsv53uh div > div:nth-child(3) {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .ldio-ekl1zsv53uh div > div:nth-child(4) {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .ldio-ekl1zsv53uh div > div:nth-child(5) {
    transform: translate(-50%, -50%) rotate(135deg);
  }

  .ldio-ekl1zsv53uh {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-ekl1zsv53uh div {
    box-sizing: content-box;
  }
  /* generated by https://loading.io/ */
`;
