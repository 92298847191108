import axios from 'axios';
import { ApiError, Pagination, PaginationResponse } from '../types';
import {
  Agreement,
  AgreementsPayload,
  AssociatedProductLine,
  AssociatedProductLinesPayload,
  AssociatedProductLinesSellerPayload,
  ContactReqObj,
  ContactResponse,
  PreviewAgrementPayload,
  ProductLine,
  CommonOrganization,
  SellerGroupInfo,
  SellerGroupPayload,
  UpdateSellerGroupRequestPayload,
  SellerGroupSmallItem,
} from '../types/sellerGroup';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { OrganizationContact } from '../types/sellerGroupContacts';
import { useToast } from '../hooks/useToast';
import { useLocation, useNavigate } from 'react-router-dom';
import { HttpStatusCode } from '../types/http-status-code.enum';
import { Dispatch, SetStateAction } from 'react';
import { useHandleOrganizationErrors } from '../utils/handleOrganizationErrors';

export const useSellerGroupAll = (payload: SellerGroupPayload) => {
  const {
    pageNumber,
    name,
    pageSize,
    sortColumn,
    sortDirection,
    agencyId,
    status,
    advancedSearch,
    isEnabled,
    shouldMatchAllCriteria,
    phone,
  } = payload;

  const filterParams = {
    pageNumber: pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    agentId: agencyId,
    name,
    fein: advancedSearch?.fein,
    fax: advancedSearch?.fax,
    doingBusinessAs: advancedSearch?.doingBusinessAs,
    status: advancedSearch?.status || status,
    shouldMatchAllCriteria,
    phone: phone,
    organizationType: advancedSearch?.organizationType,
    physicalAddress1: advancedSearch?.physicalAddress1,
    physicalAddress2: advancedSearch?.physicalAddress2,
    physicalCity: advancedSearch?.physicalCity,
    physicalStateId: advancedSearch?.physicalStateId,
    physicalZipCode: advancedSearch?.physicalZipCode,
    mailingAddress1: advancedSearch?.mailingAddress1,
    mailingAddress2: advancedSearch?.mailingAddress2,
    mailingCity: advancedSearch?.mailingCity,
    mailingStateId: advancedSearch?.mailingStateId,
    mailingZipCode: advancedSearch?.mailingZipCode,
  };

  const fetchSellerGroups = async () => {
    const fetch = await axios.post<PaginationResponse<SellerGroupInfo>>(
      `/SellerGroup/page`,
      filterParams,
    );
    return fetch.data;
  };

  const state = useQuery<PaginationResponse<SellerGroupInfo>, Error>(
    ['sellerGroups', payload],
    fetchSellerGroups,
    {
      enabled: isEnabled,
    },
  );

  return state;
};
export enum Organizations {
  SellerGroup = 'SellerGroup',
  Agent = 'Agent',
  Seller = 'Seller',
}
//OrganizationInfoQueryProps
interface SellerGroupInfoQueryProps {
  id: number | undefined;
  isEnabled: boolean;
  organisation: Organizations | '';
}
interface OrganizationContactProps {
  id: number | undefined;
  isEnabled: boolean;
}
export const useOrganizationInfo = ({ id, isEnabled, organisation }: SellerGroupInfoQueryProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchSellerGroup = async () => {
    const fetch = await axios.get(
      `/${organisation === Organizations.Seller ? 'Sellers' : organisation}/${id}`,
    );
    return fetch?.data;
  };

  const state = useQuery<CommonOrganization, ApiError>(
    ['fetchSellerGroupInfo', id],
    fetchSellerGroup,
    {
      enabled: isEnabled,
      refetchOnMount: false,
      retry: false,
      onError(err) {
        if (err.response.status === HttpStatusCode.Forbidden) {
          navigate('/NotFound', { state: { error: err.response.data.message, location } });
          return;
        }

        toast.error(err.response.data.message);
      },
    },
  );

  return state;
};

export const useAddContact = (
  onSuccess?:
    | ((
        data: ContactResponse,
        variables: ContactReqObj,
        context: unknown,
      ) => void | Promise<unknown>)
    | undefined,
  onError?:
    | ((error: ApiError, variables: ContactReqObj, context: unknown) => void | Promise<unknown>)
    | undefined,
) => {
  const postContacts = async (obj: ContactReqObj) => {
    const fetch = await axios.post(`/Contact`, obj.payload);
    return fetch?.data;
  };
  return useMutation<ContactResponse, ApiError, ContactReqObj>('contactPost', postContacts, {
    onSuccess,
    onError,
  });
};

export const useOrganizationContact = ({ id, isEnabled }: OrganizationContactProps) => {
  const toast = useToast();
  const fetchSellerGroup = async () => {
    const fetch = await axios.get(`/Contact/${id}`);
    return fetch?.data;
  };

  const state = useQuery<OrganizationContact, ApiError>(['orgContact', id], fetchSellerGroup, {
    enabled: isEnabled,
    onError(err) {
      toast.error(err.response.data.message);
    },
  });

  return state;
};
export const useOrganizationContactMutation = () => {
  const toast = useToast();
  const req = async ({ id }: { id: number }) => {
    const fetch = await axios.get(`/Contact/${id}`);
    return fetch?.data;
  };

  const state = useMutation<OrganizationContact, ApiError, { id: number }>(['contactInfo'], req, {
    onError(err) {
      toast.error(err.response.data.message);
    },
  });

  return state;
};

export const DeleteSellerGroupQuery = (
  mutationOptions?:
    | Omit<UseMutationOptions<void, ApiError, number, unknown>, 'mutationKey' | 'mutationFn'>
    | undefined,
) => {
  const fetchSellerGroup = async (id: number) => {
    const fetch = await axios.delete(`/SellerGroup/${id}`);
    return fetch?.data;
  };
  return useMutation<void, ApiError, number>(
    'deleteSellerGroup',
    fetchSellerGroup,
    mutationOptions,
  );
};

export const useSellerGroupAvailableProductLines = (
  sellerGroupId: number,
  agentId?: number,
  options?: Omit<UseQueryOptions<ProductLine[], ApiError>, 'queryKey' | 'queryFn'>,
) => {
  const toast = useToast();
  const fetchProducts = async () => {
    const fetch = await axios.get(`/SellerGroup/${sellerGroupId}/AvailableProducts`, {
      params: { agentId },
    });
    return fetch?.data;
  };
  return useQuery<ProductLine[], ApiError>(
    ['useSellerGroupAvailableProductLines', sellerGroupId, agentId],
    fetchProducts,
    {
      onError(err) {
        toast.error(err.response.data.message);
      },
      ...options,
    },
  );
};
export interface FetchSellerGroupProductLinesProps {
  sellerGroupId: number;
  pageNumber: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: string;
  shouldMatchAllCriteria: boolean;
}

export const UpdateSellerGroupQuery = ({
  setCustomFieldError,
  mutationOptions,
}: {
  setCustomFieldError: Dispatch<SetStateAction<string>>;
  mutationOptions?:
    | Omit<
        UseMutationOptions<void, ApiError, UpdateSellerGroupRequestPayload, unknown>,
        'mutationKey' | 'mutationFn'
      >
    | undefined;
}) => {
  const [handleOrgError] = useHandleOrganizationErrors();
  const patchSellerGroup = async ({ id, payload }: UpdateSellerGroupRequestPayload) => {
    const fetch = await axios.patch(`/SellerGroup/${id}`, payload);
    return fetch?.data;
  };
  return useMutation<void, ApiError, UpdateSellerGroupRequestPayload>(
    'updateSellerGroup',
    patchSellerGroup,
    {
      ...mutationOptions,
      onError(error) {
        handleOrgError({
          error,
          setCustomFieldError,
        });
      },
    },
  );
};

const generatePreviewUrl = async (previewPayload: PreviewAgrementPayload) => {
  const previewData = await axios.post('DocuSign/generateAgreementPreview', previewPayload);
  return previewData.data;
};

export const useGeneratePreview = (
  options?: Omit<
    UseMutationOptions<string, ApiError, PreviewAgrementPayload>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  return useMutation<string, ApiError, PreviewAgrementPayload>(generatePreviewUrl, options);
};

export const useSGAgreements = ({ sellerGroupId, payload, options }: AgreementsPayload) => {
  const getAgreements = async () => {
    const res = await axios.post(`SellerGroup/${sellerGroupId}/agreements`, payload);
    return res.data;
  };
  return useQuery<Pagination<Agreement>, ApiError>(
    ['useSGAgreements', payload, sellerGroupId],
    getAgreements,
    options,
  );
};

export const useSellerGroupAssociatedProductLines = ({
  sellerGroupId,
  sortColumn,
  sortDirection,
  options,
}: AssociatedProductLinesPayload) => {
  const getAll = async () => {
    const res = await axios.post(`/SellerGroup/${sellerGroupId}/associated-product-lines`, {
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      shouldMatchAllCriteria: false,
    });
    return res.data;
  };
  return useQuery<Array<AssociatedProductLine>, ApiError>(
    ['useSellerGroupAssociatedProductLines', sellerGroupId, sortColumn, sortDirection],
    getAll,
    options,
  );
};
export const useSellerAssociatedProductLines = ({
  sellerId,
  sortColumn,
  sortDirection,
  options,
}: AssociatedProductLinesSellerPayload) => {
  const getAll = async () => {
    const res = await axios.post(`/Sellers/${sellerId}/associated-product-lines`, {
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      shouldMatchAllCriteria: false,
    });
    return res.data;
  };
  return useQuery<Array<AssociatedProductLine>, ApiError>(
    ['useSellerAssociatedProductLines', sellerId, sortColumn, sortDirection],
    getAll,
    options,
  );
};

export const useAllSellerGroups = (
  options?: Omit<UseQueryOptions<SellerGroupSmallItem[], ApiError>, 'queryKey' | 'queryFn'>,
) => {
  const fetchSellerGroups = async () => {
    const res = await axios.get(`/SellerGroup/List`);
    return res.data;
  };
  return useQuery<SellerGroupSmallItem[], ApiError>(
    ['useAllSellerGroups'],
    fetchSellerGroups,
    options,
  );
};
export const useIsSendForSignaturesEnabled = ({
  agencyContactId,
  signerContactId,
  options,
}: {
  agencyContactId: number;
  signerContactId: number;
  options?: Omit<
    UseQueryOptions<{ enableSendForSignatures: boolean; message: string }, ApiError>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const fetchIsSendForSignaturesEnabled = async () => {
    const res = await axios.get(
      `/Organization/EnableSendForSignatures?agencyContactId=${agencyContactId}&signerContactId=${signerContactId}`,
    );
    return res.data;
  };
  return useQuery<{ enableSendForSignatures: boolean; message: string }, ApiError>(
    ['fetchIsSendForSignaturesEnabled', agencyContactId, signerContactId],
    fetchIsSendForSignaturesEnabled,
    options,
  );
};

export const useSellerGroupSignersList = ({
  sellerGroupId,
  agentId,
  options,
}: {
  sellerGroupId: number;
  agentId: number;
  options?: Omit<UseQueryOptions<OrganizationContact[], ApiError>, 'queryKey' | 'queryFn'>;
}) => {
  const req = async () => {
    const res = await axios.get(`/SellerGroup/${sellerGroupId}/signers/${agentId}`);
    return res.data;
  };

  const state = useQuery<OrganizationContact[], ApiError>(
    ['useSellerGroupSignersList', sellerGroupId, agentId],
    req,
    options,
  );
  return state;
};
