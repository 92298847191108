import { BaseTextFieldProps, OutlinedInputProps } from '@mui/material';
import { FC, forwardRef, useEffect, useState } from 'react';

import { StyledAppTextField } from '../AppTextField';

export interface AppTextFieldDelayedHelperTextProps extends BaseTextFieldProps {
  errorMessage?: string;
  onChange?: OutlinedInputProps['onChange'];
  InputProps?: Partial<OutlinedInputProps>;
  variant?: 'standard' | 'outlined' | 'filled';
  rounded?: 'true' | 'false';
}

export const AppTextFieldDelayedHelperText: FC<AppTextFieldDelayedHelperTextProps> = forwardRef(
  (props, ref) => {
    const { helperText, rounded } = props;
    const [showHelperText, setShowHelperText] = useState(false);
    useEffect(() => {
      if (helperText) {
        const delay = 250;

        const timer = setTimeout(() => {
          setShowHelperText(true);
        }, delay);

        return () => {
          clearTimeout(timer);
        };
      } else {
        setShowHelperText(false);
      }
    }, [helperText]);
    return (
      <StyledAppTextField
        {...props}
        ref={ref}
        label={props.label}
        error={showHelperText}
        fullWidth
        helperText={showHelperText ? helperText : ''}
        $rounded={rounded ? rounded : 'false'}
      />
    );
  },
);
