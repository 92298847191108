import styled from 'styled-components';
import { linkColor } from '../../../Theme/colorsVariables';
import { breakPointMedium } from '../../../utils';
import { CenterCenterBox } from '../../shared/FlexBox';

export const StatusText = styled('p')<{ $active: 'true' | 'false' }>`
  color: ${({ $active: active }) => (active === 'true' ? linkColor : '')};
  text-decoration: ${({ $active: active }) => (active === 'true' ? 'underline' : '')};
  cursor: ${({ $active: active }) => (active === 'true' ? 'pointer' : '')};
  margin: 0;
`;

export const IconWrapper = styled(CenterCenterBox)<{
  $isallowed: 'true' | 'false';
  iconlabel: string;
}>`
  margin: 0 4px;
  cursor: ${(props) => (props.$isallowed === 'true' ? 'pointer' : 'not-allowed')};
  svg {
    ${breakPointMedium('width: 20px; height:20px')}
  }
`;
