import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Dictionary, groupBy } from 'lodash';
import { RecentActivityItem, useMyRecentActivity } from '../../../services/dashboard';
import { ActivityDetail, InMyCourtLabel } from '../styles';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const RecentActivity = () => {
  const {
    data: myRecentActivityData,
    refetch: fetchActivity,
    isError: activityError,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useMyRecentActivity();

  const activityRef = useRef<null | HTMLElement>(null);
  const activityTopRef = useRef<null | HTMLElement>(null);

  const groupedData = useMemo(() => {
    if (myRecentActivityData) {
      const allPagesData = myRecentActivityData.data?.map((page) => page.data).flat();
      const groupData = groupBy(allPagesData, 'timeRange');
      return { Agreements: groupData };
    }
    return { Agreements: [] };
  }, [myRecentActivityData]);

  const renderDetails = ({
    agreementType,
    agentId,
    agentName,
    orgId,
    orgName,
    orgType,
    event,
    gateMessageParameter,
  }: RecentActivityItem) => {
    const agentLink = (
      <Link to={`/dashboard/Agent/${agentId}`} target="_blank">
        {agentName}
      </Link>
    );

    const orgLink = (
      <Link to={`/dashboard/${orgType}/${orgId}/Agreement`} target="_blank">
        {orgName}
      </Link>
    );

    const entityName = orgType === 'SellerGroup' ? 'Seller Group' : orgType;

    switch (event) {
      case 'Sent':
        return (
          <>
            {agreementType} was sent by {agentLink} to {entityName} {orgLink} for signature.
          </>
        );
      case 'Completed':
        return (
          <>
            {agreementType} has been successfully signed for {entityName} {orgLink} from {agentLink}
          </>
        );
      case 'Review Approved':
        return (
          <>
            {gateMessageParameter} has been approved for {agreementType} for {entityName} {orgLink}{' '}
            sent by {agentLink}
          </>
        );
      case 'Review Declined':
        return (
          <>
            {gateMessageParameter} has been declined for {agreementType} for {entityName} {orgLink}{' '}
            sent by {agentLink}
          </>
        );
      case 'Declined':
        return (
          <>
            {agreementType} has been declined for {agreementType} for {entityName} {orgLink} sent by{' '}
            {agentLink}
          </>
        );
      case 'Voided':
        return (
          <>
            {agreementType} has been voided for {agreementType} for {entityName} {orgLink} sent by{' '}
            {agentLink}
          </>
        );
      default:
        return 'unknown event';
    }
  };

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollHeight, scrollTop, offsetHeight } = event.currentTarget;

    const scrollLength = scrollHeight - offsetHeight - 100;

    if (!isFetching && scrollTop >= scrollLength && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    fetchActivity();
  }, [fetchActivity]);

  if (activityError) {
    return <div>Error loading activity, please try sometime later</div>;
  }

  return (
    <Box sx={{ overflowY: 'auto' }} onScroll={onScroll}>
      {Object.keys(groupedData).map((groupName, index) => {
        const groupData: Dictionary<RecentActivityItem[]> = groupedData[
          groupName as keyof typeof groupedData
        ] as any;

        return (
          <Accordion key={groupName} defaultExpanded={index === 0} square disableGutters>
            <AccordionSummary
              expandIcon={<ArrowForwardIosOutlinedIcon sx={{ fontSize: '0.9rem' }} />}
            >
              <InMyCourtLabel>{groupName}</InMyCourtLabel>
            </AccordionSummary>
            {Object.keys(groupData).map((timeRange) => {
              const items = groupData[timeRange];
              return (
                <Accordion
                  key={timeRange}
                  sx={{ paddingLeft: '1em', marginBottom: 0 }}
                  square
                  defaultExpanded
                  disableGutters
                  onChange={() => {
                    if (timeRange === Object.keys(groupData).at(-1)) {
                      if (!isFetching) {
                        fetchNextPage();
                      }
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowForwardIosOutlinedIcon sx={{ fontSize: '0.9rem' }} />}
                  >
                    <InMyCourtLabel>{timeRange}</InMyCourtLabel>
                  </AccordionSummary>

                  <Box ref={activityTopRef}></Box>
                  {items.map((item) => {
                    return (
                      <AccordionDetails key={item.lastModifiedOn + item.agreementId + item.event}>
                        <ActivityDetail>{renderDetails(item)}</ActivityDetail>
                      </AccordionDetails>
                    );
                  })}
                  <Box ref={activityRef}></Box>
                </Accordion>
              );
            })}
          </Accordion>
        );
      })}
    </Box>
  );
};
export default RecentActivity;
