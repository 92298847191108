import { useTranslation } from 'react-i18next';
import { BoxRequired } from '../FlexBox';
import LabelRequired from '../LabelRequired';
import { ActiveText, PageHeaderNameContainer, PageHeading, Title } from './styles';
import { PageHeaderContent } from './types';

export default function PageHeaderContentHost({
  title,
  name,
  status,
  showRequiredLabel,
  color,
}: PageHeaderContent) {
  const { t } = useTranslation();

  return (
    <PageHeaderNameContainer $name={name} $title={title}>
      <PageHeading $color={`${color || ''}`}>
        <span>{t(title || '')}</span>
      </PageHeading>
      <Title>{name}</Title>
      {status !== undefined && typeof status === 'boolean' && (
        <ActiveText $active={`${status}`} className={'statusLabel'}>
          {status ? <span>{t('status.active')}</span> : <span>{t('status.inactive')}</span>}
        </ActiveText>
      )}

      {showRequiredLabel && (
        <BoxRequired>
          <LabelRequired />
        </BoxRequired>
      )}
    </PageHeaderNameContainer>
  );
}
