import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircleOutlineRounded } from '@mui/icons-material';
import { Grid2, FormGroup, Checkbox, Box, useMediaQuery, useTheme } from '@mui/material';
import { DatePicker, PickersDay, pickersDayClasses } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { useForm, Controller, Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { QuickConfirmationModalContext } from '../../../context/QuickModal';
import { UserContext } from '../../../context/User';
import { useDataloss } from '../../../hooks/useDataloss';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { useToast } from '../../../hooks/useToast';
import {
  AddContactBtn,
  ProductGrid,
  ProductLinesBoxScrollable,
} from '../../SellerGroupTabs/ProductLinesTab/AddProductLines/styles';
import { StickyContainer } from '../../../Theme/oldStylesInUse/oldAddSellerStyles';
import { SecondHeaderBox } from '../../../pages/OrganizationContactInfoPage/styles';
import { addProductLineValidationSchema } from '../../../Resolvers/addProductLineValidationSchema';
import { useAgentContactsAll } from '../../../services/agentService';
import AuthService from '../../../services/authService';
import { useCheckOrgHasInProgressAgreements } from '../../../services/organization';
import {
  useIsSendForSignaturesEnabled,
  useGeneratePreview,
} from '../../../services/sellerGroupServices';
import {
  ProviderFetchingQuery,
  useSellerInfo,
  useAllAgents,
  useSellerAvailableProductLines,
  useSellerSignersList,
} from '../../../services/sellerServices';
import { White, MainRed } from '../../../Theme/colorsVariables';
import { AddProductLineFormValues } from '../../../types/sellerGroup';
import { pastingCheck } from '../../../utils';
import { FeinBox } from '../../common/styles/feinBox';
import { StyledTypography } from '../../Forms/RoleForm/noPermissionsText';
import { CancelModal } from '../../modal/CancelModal';
import { AppActionButton } from '../../shared/AppActionButton';
import AppAutocompleteSingleSelect from '../../shared/AppAutocompleteSingleSelect';
import { AppCancelButton } from '../../shared/AppCancelButton';
import PageHeaderContentHost from '../../shared/AppPageHeaderContentHost';
import { PageHeaderContainer } from '../../shared/AppPageHeaderContentHost/styles';
import { AppTextField } from '../../shared/AppTextField';
import { AppWarningParagraph } from '../../shared/AppWarningParagraph';
import {
  SpaceBetweenBox,
  AlignCenterBox,
  JustifyContentRightBox,
  FlexBox,
} from '../../shared/FlexBox';
import { useDocuSignSendForSignatures } from '../../../services/DocuSignService';
import { useQuery } from '../../../hooks/useQuery';
import { CheckboxMain } from '../../PermissionSets/styles';

export const AddProductLinesSeller = ({
  customOrgId,
  setIsUnsavedData,
}: {
  customOrgId?: string;
  setIsUnsavedData?: Dispatch<SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const { id: urlSellerId } = useParams();
  const id = useMemo(() => {
    return customOrgId ? customOrgId : urlSellerId;
  }, [customOrgId, urlSellerId]);
  const location = useLocation();
  const {
    state: savedFormValuesInLocationState,
  }: { state: { form: AddProductLineFormValues } | null } = location;

  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const { setQuickModal } = useContext(QuickConfirmationModalContext);
  const agencyId = useQuery('agencyId');

  const isCreateOrganizationContact = usePermissions(PermissionKeys.CreateContact);
  const isReadAgent = usePermissions(PermissionKeys.ReadAgent);

  const { data: providerInfo } = ProviderFetchingQuery();
  const { data: commonData } = AuthService.useCommonDetailsQuery();

  const agreementEffectiveDateRangeOver = useMemo(() => {
    const obj = commonData?.find((item) => item.type === 'AgreementEffectiveDateRangeOver');
    if (obj) {
      return +obj.value;
    }
    return null;
  }, [commonData]);
  const agreementEffectiveDateRangeUnder = useMemo(() => {
    const obj = commonData?.find((item) => item.type === 'AgreementEffectiveDateRangeUnder');
    if (obj) {
      return +obj.value;
    }
    return null;
  }, [commonData]);

  const [confirmPopup, setConfirmPopup] = useState(false);

  const {
    control,
    watch,
    formState: { errors, defaultValues },
    setValue,
    resetField,
  } = useForm<AddProductLineFormValues>({
    defaultValues: {
      agent: null,
      agentRelationshipOwner: null,
      effectiveDate: dayjs(new Date()),
      legacyProductLines: [],
      productLines: [],
      signer: null,
      legacyProductLinesFlag: false,
      message: '',
    },
    resolver: yupResolver(addProductLineValidationSchema),
  });

  const allFields = watch();

  const {
    agent: selectedAgent,
    signer,
    message,
    productLines,
    agentRelationshipOwner,
    effectiveDate,
    legacyProductLines: legacyProductLinesFormValue,
  } = allFields;

  useEffect(() => {
    if (savedFormValuesInLocationState?.form) {
      setValue('agent', savedFormValuesInLocationState?.form.agent);
      setValue(
        'agentRelationshipOwner',
        savedFormValuesInLocationState?.form.agentRelationshipOwner,
      );
      setValue('effectiveDate', savedFormValuesInLocationState?.form.effectiveDate);
      setValue('signer', savedFormValuesInLocationState?.form.signer);
      setValue('productLines', savedFormValuesInLocationState?.form.productLines);
      setValue('legacyProductLines', savedFormValuesInLocationState?.form.legacyProductLines);
      setValue(
        'legacyProductLinesFlag',
        savedFormValuesInLocationState?.form.legacyProductLinesFlag,
      );
      setValue('message', savedFormValuesInLocationState?.form.message);
      window.history.replaceState(null, '', location.pathname);
    }
  }, [location.pathname, savedFormValuesInLocationState, setValue]);

  const { data: agencies } = useAllAgents({
    onSuccess(data) {
      if (user && user.userType === 'Agency') {
        const userAgent = data.find((agentObject) => agentObject.agencyId === user.orgId);
        if (userAgent) {
          resetField('agent', {
            defaultValue: {
              label: `${userAgent.agencyName}<span class="vl"></span>${pastingCheck(
                userAgent.phoneNumber || '',
              )}`,
              value: userAgent.agencyId,
            },
            keepDirty: false,
            keepError: false,
            keepTouched: false,
          });
        }
      }
    },
  });

  const { data: agentContacts, isFetching: isAgentContactsFetching } = useAgentContactsAll({
    agentId: Number(selectedAgent?.value),
    options: {
      enabled: !!selectedAgent?.value,
      onSuccess(data) {
        const userInAgentContacts = data?.find(
          (agentContact) =>
            user?.name === `${agentContact.firstName} ${agentContact.lastName}` &&
            user?.email === agentContact.email,
        );
        if (user && user.userType === 'Agency') {
          if (userInAgentContacts) {
            resetField('agentRelationshipOwner', {
              defaultValue: {
                label: `${userInAgentContacts.firstName} ${userInAgentContacts.lastName}<span class="vl"></span>${userInAgentContacts.title}<span class="vl"></span>${userInAgentContacts.email}`,
                value: userInAgentContacts.contactId,
              },
              keepDirty: false,
              keepError: false,
              keepTouched: false,
            });
          }
        }
        if (user && user.userType === 'Provider') {
          if (data.length === 1) {
            const singleContact = data[0];
            setValue('agentRelationshipOwner', {
              label: `${singleContact.firstName} ${singleContact.lastName}<span class="vl"></span>${
                singleContact.title || ''
              }<span class="vl"></span>${singleContact.email}`,
              value: singleContact.contactId,
            });
          }
        }
      },
    },
  });

  const { data: signersListByAgent } = useSellerSignersList({
    sellerId: Number(id) || 0,
    agentId: selectedAgent?.value || 0,
    options: {
      enabled: !!selectedAgent?.value && !!id,
      onSuccess(data) {
        if (data.length === 1) {
          const singleSigner = data[0];
          setValue('signer', {
            label: `${singleSigner.firstName} ${singleSigner.lastName}<span class="vl"></span>${
              singleSigner.title || ''
            }<span class="vl"></span>${singleSigner.email}`,
            value: singleSigner.contactId,
          });
        }
      },
    },
  });

  const { data: availableProductLines, isFetching: isProductsFetching } =
    useSellerAvailableProductLines(Number(id), selectedAgent?.value, {
      onSuccess(data) {
        if (!savedFormValuesInLocationState?.form) {
          resetField('productLines', {
            defaultValue: data
              .filter((item) => !item.isLegacyAgreementProduct)
              .map((item) => item.productId),
            keepDirty: false,
            keepError: false,
            keepTouched: false,
          });
        }
        const legacyProducts = data.filter((item) => item.isLegacyAgreementProduct);
        if (legacyProducts.length) {
          resetField('legacyProductLines', {
            defaultValue: legacyProducts.map((item) => item.productId),
            keepDirty: false,
            keepError: false,
            keepTouched: false,
          });
          resetField('legacyProductLinesFlag', {
            defaultValue: true,
            keepDirty: false,
            keepError: false,
            keepTouched: false,
          });
        } else {
          resetField('legacyProductLinesFlag', {
            defaultValue: false,
            keepDirty: false,
            keepError: false,
            keepTouched: false,
          });
        }
      },
    });

  const { data: isSendForSignaturesEnabledData } = useIsSendForSignaturesEnabled({
    agencyContactId: agentRelationshipOwner && signer ? agentRelationshipOwner?.value : 0,
    signerContactId: agentRelationshipOwner && signer ? signer?.value : 0,
  });

  const { data: sellerData } = useSellerInfo(Number(id), {});

  const legacyProductLines = useMemo(() => {
    if (availableProductLines?.length) {
      return availableProductLines
        .filter((item) => item.isLegacyAgreementProduct)
        .map((item) => ({
          name: item.productName,
          id: item.productId,
        }));
    }
    return [];
  }, [availableProductLines]);

  const signerOptions = useMemo(
    () =>
      signersListByAgent?.map((contact) => ({
        label: `${contact.firstName} ${contact.lastName}<span class="vl"></span>${
          contact.title || ''
        }<span class="vl"></span>${contact.email}`,
        value: Number(contact.contactId),
      })) || [],
    [signersListByAgent],
  );

  const agents = useMemo(() => {
    return (
      agencies?.map((agent) => {
        return {
          label: `${agent.agencyName}<span class="vl"></span>${pastingCheck(
            agent?.phoneNumber || '',
          )}`,
          value: agent.agencyId,
        };
      }) || []
    );
  }, [agencies]);

  const agentRelationshipOwners = useMemo(() => {
    return (
      agentContacts?.map((contact) => {
        return {
          label: `${contact.firstName} ${contact.lastName}<span class="vl"></span>${
            contact.title || ''
          }<span class="vl"></span>${contact.email}`,
          value: Number(contact.contactId),
        };
      }) || []
    );
  }, [agentContacts]);

  const sortedProducts = useMemo(() => {
    return availableProductLines
      ? availableProductLines
          .filter((item) => !item.isLegacyAgreementProduct)
          .sort((a, b) => a.productName.localeCompare(b.productName))
      : [];
  }, [availableProductLines]);

  const { mutate: getDocumentUrl } = useGeneratePreview({
    onSuccess(data) {
      window.open(data, '_blank', 'noreferrer');
    },
    onError(error) {
      setQuickModal({
        isOpen: true,
        modalTitle: 'Error!',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: sendForSignature } = useDocuSignSendForSignatures({
    options: {
      onSuccess(data) {
        toast.success(data.message);
        navigate(`/dashboard/Seller/${id}/ProductLine`);
      },
      onError(error) {
        setQuickModal({
          isOpen: true,
          modalTitle: 'Error!',
          message: error.response.data.message,
        });
      },
    },
  });

  const previewAgreement = () => {
    getDocumentUrl({
      redirectUrl: process.env.REACT_APP_DOCUSIGN_REDIRECT_URL,
      contactId: Number(signer?.value),
      effectiveDate: effectiveDate?.toISOString() || '',
      orgId: parseInt(id || ''),
      message: message,
      productIds: [
        ...productLines.map((x) => x.toString()),
        ...legacyProductLines.map((x) => x.id.toString()),
      ],
      providerOrgId: providerInfo?.orgId || 1,
      agencyContactId: Number(agentRelationshipOwner?.value),
    });
  };

  const onSendForSignatures = () => {
    sendForSignature({
      redirectUrl: process.env.REACT_APP_DOCUSIGN_REDIRECT_URL,
      orgId: parseInt(id || ''),
      productIds: [...productLines, ...legacyProductLines.map((item) => item.id)],
      contactId: Number(signer?.value),
      message: message,
      agencyContactId: Number(agentRelationshipOwner?.value),
      effectiveDate: effectiveDate?.toISOString() || '',
      providerOrgId: providerInfo?.orgId || 1,
    });
  };

  const isUnsavedData = useMemo(() => {
    const currentFormDefaultValues = {
      ...defaultValues,
      effectiveDate: {
        year: (defaultValues as AddProductLineFormValues).effectiveDate?.get('y'),
        month: (defaultValues as AddProductLineFormValues).effectiveDate?.get('month'),
        day: (defaultValues as AddProductLineFormValues).effectiveDate?.get('D'),
      },
      productLines: (defaultValues?.productLines as number[])?.sort((a, b) => a - b),
    };

    const currentFormValues = {
      ...allFields,
      effectiveDate: {
        year: allFields.effectiveDate?.get('y'),
        month: allFields.effectiveDate?.get('month'),
        day: allFields.effectiveDate?.get('D'),
      },
      productLines: allFields.productLines?.sort((a, b) => a - b),
    };

    const result = !isEqual(currentFormValues, currentFormDefaultValues);

    return result;
  }, [defaultValues, allFields]);

  useEffect(() => {
    if (setIsUnsavedData) {
      setIsUnsavedData(isUnsavedData);
    }
  }, [isUnsavedData, setIsUnsavedData]);

  const onDiscardChanges = useCallback(() => {
    navigate(`/dashboard/Seller/${id}/ProductLine`);
    setConfirmPopup(false);
  }, [id, navigate]);

  const onSaveChanges = useCallback(() => {}, []);

  const onExit = useCallback(() => {}, []);

  useDataloss(isUnsavedData, onDiscardChanges, onSaveChanges, onExit, true);

  return (
    <>
      {!customOrgId && (
        <PageHeaderContainer mb={3}>
          <SpaceBetweenBox>
            <PageHeaderContentHost
              title="Seller"
              name={sellerData?.legalName}
              status={sellerData?.status}
            />
          </SpaceBetweenBox>
        </PageHeaderContainer>
      )}
      <SecondHeaderBox>
        <PageHeaderContentHost color={`${White}`} title={'Create Agreement'} />
        <AlignCenterBox>
          {!customOrgId && (
            <AppCancelButton
              onClick={() => {
                if (isUnsavedData) {
                  setConfirmPopup(true);
                } else {
                  setConfirmPopup(false);
                  navigate(`/dashboard/Seller/${id}/ProductLine`);
                }
              }}
            />
          )}
          <AppActionButton
            disabled={
              legacyProductLinesFormValue?.length
                ? !selectedAgent ||
                  !agentRelationshipOwner ||
                  !signer ||
                  !effectiveDate?.date() ||
                  !isSendForSignaturesEnabledData?.enableSendForSignatures
                : !selectedAgent ||
                  !agentRelationshipOwner ||
                  !signer ||
                  productLines?.length === 0 ||
                  !effectiveDate?.date() ||
                  !isSendForSignaturesEnabledData?.enableSendForSignatures
            }
            onClick={() => {
              previewAgreement();
            }}
          >
            {t('action.previewAgreement')}
          </AppActionButton>
          <AppActionButton
            disabled={
              legacyProductLinesFormValue?.length
                ? !selectedAgent ||
                  !agentRelationshipOwner ||
                  !signer ||
                  !effectiveDate?.date() ||
                  !isSendForSignaturesEnabledData?.enableSendForSignatures
                : !selectedAgent ||
                  !agentRelationshipOwner ||
                  !signer ||
                  productLines?.length === 0 ||
                  !effectiveDate?.date() ||
                  !isSendForSignaturesEnabledData?.enableSendForSignatures
            }
            onClick={() => {
              onSendForSignatures();
            }}
          >
            {t('action.sendForSignatures')}
          </AppActionButton>
        </AlignCenterBox>
      </SecondHeaderBox>
      {isSendForSignaturesEnabledData?.message && (
        <JustifyContentRightBox>
          <AppWarningParagraph>
            <i>{isSendForSignaturesEnabledData?.message}</i>
          </AppWarningParagraph>
        </JustifyContentRightBox>
      )}
      <FlexBox justifyContent={'space-between'} height={'calc( 100% - 34px )'}>
        <ProductGrid width={'39%'}>
          <Grid2 container spacing={isSmall ? 1 : 2} mt={2}>
            <Grid2 size={7}>
              <AppAutocompleteSingleSelect
                control={control as unknown as Control<FieldValues>}
                name={'agent'}
                label={'Agent'}
                options={agents}
                rules={{
                  required: true,
                }}
                disabled={user?.userType === 'Agency' || !!agencyId}
                errorMessage={errors.agent?.message}
                onChange={() => {
                  setValue('agentRelationshipOwner', null);
                  setValue('signer', null);
                }}
              />
            </Grid2>
            <Grid2 size={5} />

            <Grid2 size={7}>
              <AppAutocompleteSingleSelect
                control={control as unknown as Control<FieldValues>}
                name={'agentRelationshipOwner'}
                label={'Agent Relationship Owner'}
                options={agentRelationshipOwners}
                disabled={!selectedAgent || agentRelationshipOwners.length === 0}
                rules={{
                  required: true,
                }}
                errorMessage={errors.agentRelationshipOwner?.message}
              />
            </Grid2>

            <Grid2 size={5} alignItems={'center'} display={'flex'}>
              <AddContactBtn
                variant="outlined"
                disabled={!isCreateOrganizationContact || !isReadAgent || !selectedAgent}
                startIcon={<AddCircleOutlineRounded />}
                onClick={() => {
                  navigate(
                    `/dashboard/Agent/${selectedAgent?.value}/AddContact?returnUrl=/dashboard/Seller/${id}/CreateAgreement`,
                    { state: { form: allFields } },
                  );
                }}
              >
                {t('action.addAgentContact')}
              </AddContactBtn>
            </Grid2>
            {agentRelationshipOwners.length === 0 &&
              !isAgentContactsFetching &&
              !!selectedAgent && (
                <Grid2 size={12}>
                  <FeinBox>{t('sellerGroup.productLine.noAgentContactWarning')}</FeinBox>
                </Grid2>
              )}
            <ProductLinesBoxScrollable>
              <StickyContainer>
                <b>{t('sellerGroup.productLine.selectProductLine')}</b>
              </StickyContainer>
              {!sortedProducts.length && !isProductsFetching && (
                <Grid2 container>
                  <Grid2 size={{ xs: 12 }}>
                    <StyledTypography>{t('sellerGroup.productLine.noRecords')}</StyledTypography>
                  </Grid2>
                </Grid2>
              )}
              <FeinBox>{errors.productLines?.message}</FeinBox>
              <Controller
                name="productLines"
                control={control}
                render={({ field }) => (
                  <>
                    {sortedProducts.map((item) => (
                      <Grid2 size={{ xs: 12 }} key={item.productId}>
                        <AlignCenterBox>
                          <FormGroup>
                            <CheckboxMain
                              control={
                                <Checkbox
                                  color="success"
                                  value={item.productId}
                                  checked={field.value?.indexOf(item.productId) >= 0}
                                  onChange={(event, checked) => {
                                    if (checked) {
                                      field.onChange([...field.value, +event.target.value]);
                                    } else {
                                      field.onChange(
                                        field.value.filter(
                                          (value) => value !== +event.target.value,
                                        ),
                                      );
                                    }
                                  }}
                                />
                              }
                              label={item.productName}
                            />
                          </FormGroup>
                        </AlignCenterBox>
                      </Grid2>
                    ))}
                  </>
                )}
              />
              {!!legacyProductLines.length && (
                <>
                  <StickyContainer>
                    <b>{t('sellerGroup.productLine.legacyProductLine')}</b>
                  </StickyContainer>
                  <Controller
                    name="legacyProductLines"
                    control={control}
                    render={({ field }) => (
                      <>
                        {legacyProductLines.map((item) => (
                          <Grid2 size={{ xs: 12 }} key={item.id}>
                            <AlignCenterBox>
                              <FormGroup>
                                <CheckboxMain
                                  disabled
                                  control={
                                    <Checkbox
                                      color="success"
                                      value={item.id}
                                      checked={field.value?.indexOf(item.id) >= 0}
                                      onChange={(event, checked) => {
                                        if (checked) {
                                          field.onChange([...field.value, +event.target.value]);
                                        } else {
                                          field.onChange(
                                            field.value.filter(
                                              (value) => value !== +event.target.value,
                                            ),
                                          );
                                        }
                                      }}
                                    />
                                  }
                                  label={item.name}
                                />
                              </FormGroup>
                            </AlignCenterBox>
                          </Grid2>
                        ))}
                      </>
                    )}
                  />
                </>
              )}
            </ProductLinesBoxScrollable>
          </Grid2>
        </ProductGrid>

        <Box width={'60%'}>
          <Grid2 container spacing={isSmall ? 1 : 2} size={12}>
            <Grid2 container spacing={isSmall ? 1 : 2} mt={2} size={12}>
              <Grid2 size={8}>
                <AppAutocompleteSingleSelect
                  control={control as unknown as Control<FieldValues>}
                  name={'signer'}
                  label={'Seller Signer'}
                  options={signerOptions}
                  rules={{
                    required: true,
                  }}
                  disabled={signerOptions.length === 0}
                  errorMessage={errors.signer?.message}
                />
              </Grid2>
              <Grid2 size={4} display={'flex'} alignItems={'center'}>
                <AddContactBtn
                  variant="outlined"
                  disabled={!isCreateOrganizationContact}
                  startIcon={<AddCircleOutlineRounded />}
                  onClick={() => {
                    navigate(
                      `/dashboard/Seller/${id}/AddContact?returnUrl=/dashboard/Seller/${id}/CreateAgreement`,
                      { state: { form: allFields } },
                    );
                  }}
                >
                  {t('add.contact')}
                </AddContactBtn>
              </Grid2>
              {signerOptions.length === 0 ? (
                user?.userType === 'Agency' || !!agencyId ? (
                  <Grid2 size={12}>
                    <FeinBox>{t('sellerGroup.productLine.noContactWarning')}</FeinBox>
                  </Grid2>
                ) : (
                  <Grid2 size={12}>
                    <FeinBox>{t('sellerGroup.productLine.noContactWarningProvider')}</FeinBox>
                  </Grid2>
                )
              ) : undefined}

              <Grid2 size={8}>
                <Controller
                  name="effectiveDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label="Effective Date"
                      minDate={
                        agreementEffectiveDateRangeUnder &&
                        dayjs().subtract(agreementEffectiveDateRangeUnder, 'day')
                      }
                      maxDate={
                        agreementEffectiveDateRangeOver &&
                        dayjs().add(agreementEffectiveDateRangeOver, 'day')
                      }
                      slots={{
                        textField: AppTextField,
                        day: (props) => (
                          <PickersDay
                            {...props}
                            sx={{
                              [`&&.${pickersDayClasses.selected}`]: {
                                backgroundColor: MainRed,
                              },
                            }}
                          />
                        ),
                      }}
                      slotProps={{
                        day: {
                          sx: {
                            backgroundColor: 'red',
                          },
                        },
                        textField: {
                          required: true,
                        },
                      }}
                    />
                  )}
                />
              </Grid2>

              <Grid2 size={12}>
                <Controller
                  control={control}
                  name="message"
                  render={({ field }) => {
                    return <AppTextField {...field} multiline minRows={6} label={'Message'} />;
                  }}
                />
              </Grid2>
            </Grid2>
          </Grid2>
        </Box>
      </FlexBox>
      {confirmPopup && (
        <CancelModal
          modalTitle="Warning"
          onDiscardChanges={onDiscardChanges}
          onSaveChanges={onSaveChanges}
          onExit={() => {
            setConfirmPopup(false);
          }}
          hideSaveDataButton={true}
          isOpened={confirmPopup}
        />
      )}
    </>
  );
};

export const AddProductLinesSellerProtected = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const toast = useToast();

  const isSendForSignatures = usePermissions(PermissionKeys.SendForSignatures);

  const { data: isAgreementInProcess, isFetching: isAgreementInProcessFetching } =
    useCheckOrgHasInProgressAgreements(Number(id), {
      enabled: isSendForSignatures,
      onError(err) {
        toast.error(err.response.data.message);
      },
    });

  if (!isSendForSignatures) {
    return (
      <Grid2 container>
        <Grid2 size={{ xs: 12 }}>
          <StyledTypography>{t('relationships.organisation.view.permission')}</StyledTypography>
        </Grid2>
      </Grid2>
    );
  }

  if (isAgreementInProcessFetching) {
    return <></>;
  }

  if (isAgreementInProcess) {
    return (
      <Grid2 container>
        <Grid2 size={{ xs: 12 }}>
          <StyledTypography>{t('productLine.inProgress.warning')}</StyledTypography>
        </Grid2>
      </Grid2>
    );
  }
  return <AddProductLinesSeller />;
};
