import { Box } from '@mui/material';
import styled from 'styled-components';
import { linkColor } from '../../Theme/colorsVariables';

const AddTemplateLabel = styled(Box)<{ $active: string }>`
  color: ${({ $active: active }) => (active === 'true' ? linkColor : '#77868f')};
  text-decoration: ${({ $active: active }) => (active === 'true' ? 'underline' : 'none')};
  letter-spacing: 0px;
  cursor: ${({ $active: active }) => (active === 'true' ? 'pointer' : 'not-allowed')};
  font-weight: normal;
  font-size: 16px;
  display: inline;
`;

const DeleteTemplateLabel = styled(Box)`
  color: ${linkColor};
  text-decoration: underline;
  letter-spacing: 0px;
  cursor: pointer;
  font-weight: normal;
  font-size: 16px;
  display: inline;
  padding-right: 10px;
`;

export { AddTemplateLabel, DeleteTemplateLabel };
