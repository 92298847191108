import { Table, TableContainer } from '@mui/material';
import styled from 'styled-components';
import { MainTextColor, UltimateGray, White } from '../../../Theme/colorsVariables';
import { breakPointMedium } from '../../../utils/MediaBreakPoint';
import { FlexBox } from '../../shared/FlexBox';

const Data = `
font-size: 13px;
line-height: 13px;
padding: 6px 8px;
`;

const CustomTable = styled(Table)`
  border-radius: 20px 20px 0px 0px;
  background: #ffffff;
  thead {
    tr {
      th {
        white-space: nowrap;
        border-bottom: 1px solid ${UltimateGray};
        ${breakPointMedium(Data)}
        z-index: 90;

        span {
          div {
            font-size: 18px;
            font-family: Gotham-MonoSpace-Numbers, sans-serif;
            color: #3f4346;
            font-weight: 600;
            ${breakPointMedium('font-size: 13px;')}
          }
        }
      }
    }
  }
  tbody {
    tr {
      background: ${White};
      td {
        font-size: 16px;
        color: ${MainTextColor};
        font-family: Gotham-MonoSpace-Numbers, sans-serif;
        font-weight: 400;
        letter-spacing: 0.6px;
        border-bottom: 1px solid ${UltimateGray};
        word-break: auto-phrase;
        ${breakPointMedium('font-size: 11px; padding: 6px 8px;')}
      }
    }
  }
`;

export const CustomTableContainer = styled(TableContainer)<{ $height: string }>`
  max-height: ${(props) => props.$height};
  height: 100%;
`;

export const TableGrowContainer = styled(FlexBox)`
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  background: ${White};
`;

export { CustomTable };
