import { useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AppActionButton,
  AppActionButtonsContainer,
} from '../../components/shared/AppActionButton';
import { AppDeleteButton } from '../../components/shared/AppDeleteButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { FlexBox } from '../../components/shared/FlexBox';
import { StickyContainer } from '../../Theme/oldStylesInUse/oldAddSellerStyles';
import { BoxStepper, Label, MainStepper, MainSteps } from '../AgencyInfo/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import {
  DeleteSellerGroupQuery,
  Organizations,
  useOrganizationInfo,
  useSGAgreements,
} from '../../services/sellerGroupServices';
import DeleteEntityModal from '../../components/modal/DeleteEntityModal';
import { ApiError } from '../../types';
import { useToast } from '../../hooks/useToast';
import { NoPermissionMessage } from '../../components/shared/NoPermissionMessage';
import { useSellerAll } from '../../services/sellerServices';
import { AgreementStatus } from '../../types/agreements';
import { useCheckOrgHasInProgressAgreements } from '../../services/organization';
import { useOrganizationContacts } from '../../services/sellerGroupContactsService';
import { UnsavedDataContext } from '../../context/UnsavedData';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { AppAutocomplete } from '../../components/shared/AppAutocomplete';
import AppListItem from '../../components/shared/AppListItem';
import { AppTextField } from '../../components/shared/AppTextField';
import { SecondaryBackground } from '../../Theme/colorsVariables';
import { useQueryClient } from 'react-query';

enum SellerGroupSteps {
  BusinessInfo = 'Business Information',
  Sellers = 'Sellers',
  Contacts = 'Contacts',
  ProductLines = 'Product Lines',
  Agreements = 'Agreements',
}

const ViewSellerGroup = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [contactsCount, setContactsCount] = useState(0);

  const { data: sellers } = useSellerAll({
    pageNumber: 1,
    pageSize: 5,
    sellerGroupIds: [Number(id)],
  });

  const { data: organizationContacts } = useOrganizationContacts({
    pageNumber: 1,
    pageSize: 5,
    orgId: Number(id),
    shouldMatchAllCriteria: false,
  });

  const toast = useToast();
  const isReadOrganization = usePermissions(PermissionKeys.ReadOrganization);
  const isDeleteOrganization = usePermissions(PermissionKeys.DeleteOrganization);
  const isUpdateOrganization = usePermissions(PermissionKeys.UpdateOrganization);
  const isCreateSellerGroupContact = usePermissions(PermissionKeys.CreateContact);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [steps, setSteps] = useState<string[]>([]);

  const { data: sellerGroupData, refetch: refetchSellerGroupData } = useOrganizationInfo({
    id: Number(id),
    isEnabled: isReadOrganization,
    organisation: Organizations.SellerGroup,
  });

  const { data: agreementsResponse, refetch: refetchAgreementsList } = useSGAgreements({
    sellerGroupId: Number(id),
    payload: {
      pageNumber: 1,
      pageSize: 100,
      shouldMatchAllCriteria: true,
    },
  });

  const { data: isAgreementInProcess, refetch: refetchIsAgreementsInProgress } =
    useCheckOrgHasInProgressAgreements(Number(id));

  const isCompletedAgreement = useMemo(() => {
    return agreementsResponse?.data.some(
      (agreement) => agreement.status === AgreementStatus.Completed,
    );
  }, [agreementsResponse?.data]);

  useEffect(() => {
    setSteps(t('sellerGroup.steps', { returnObjects: true }));
    if (!isReadOrganization) {
      setSteps(
        (t('sellerGroup.steps', { returnObjects: true }) as string[]).filter(
          (item) => item !== 'Agreements',
        ),
      );
    }
  }, [sellerGroupData, t, isReadOrganization]);

  const [activeStep, setActiveStep] = useState(SellerGroupSteps.BusinessInfo);

  const navigateSwitcher: Record<SellerGroupSteps, (sellerGroupId: number) => void> = {
    [SellerGroupSteps.BusinessInfo]: (sellerGroupId: number) => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/SellerGroup/${sellerGroupId}`,
        });
      } else {
        navigate(`/dashboard/SellerGroup/${sellerGroupId}`);
      }
    },
    [SellerGroupSteps.Sellers]: (sellerGroupId: number) => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/SellerGroup/${sellerGroupId}/Seller`,
        });
      } else {
        navigate(`/dashboard/SellerGroup/${sellerGroupId}/Seller`);
      }
    },
    [SellerGroupSteps.ProductLines]: (sellerGroupId: number) => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/SellerGroup/${sellerGroupId}/ProductLine`,
        });
      } else {
        navigate(`/dashboard/SellerGroup/${sellerGroupId}/ProductLine`);
      }
    },
    [SellerGroupSteps.Contacts]: (sellerGroupId: number) => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/SellerGroup/${sellerGroupId}/Contact`,
        });
      } else {
        navigate(`/dashboard/SellerGroup/${sellerGroupId}/Contact`);
      }
    },
    [SellerGroupSteps.Agreements]: (sellerGroupId: number) => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/SellerGroup/${sellerGroupId}/Agreement`,
        });
      } else {
        navigate(`/dashboard/SellerGroup/${sellerGroupId}/Agreement`);
      }
    },
  };

  useEffect(() => {
    if (location.pathname.includes('/Contact')) {
      setActiveStep(SellerGroupSteps.Contacts);
    } else if (location.pathname.includes('/ProductLine')) {
      setActiveStep(SellerGroupSteps.ProductLines);
    } else if (location.pathname.indexOf('/Seller') !== location.pathname.lastIndexOf('/Seller')) {
      setActiveStep(SellerGroupSteps.Sellers);
    } else if (location.pathname.includes('/Agreement')) {
      setActiveStep(SellerGroupSteps.Agreements);
    } else {
      setActiveStep(SellerGroupSteps.BusinessInfo);
    }
  }, [location]);

  const onDeleteSuccess = () => {
    queryClient.removeQueries({ queryKey: ['sellerGroups'] });

    navigate('/dashboard/SellerGroup');
    toast.success(
      t('success.delete', { entityType: 'Seller Group', entityName: sellerGroupData?.legalName }),
    );
  };

  const onDeleteError = (error: ApiError) => {
    setIsDeleteModalOpen(false);
    toast.error(error.response.data.message);
    navigate('/dashboard/SellerGroup');
  };

  const { mutate: deleteSellerGroup } = DeleteSellerGroupQuery({
    onSuccess: onDeleteSuccess,
    onError: onDeleteError,
  });

  const deleteSellerGroupHandler = () => {
    deleteSellerGroup(Number(id));
  };

  useEffect(() => {
    setContactsCount(organizationContacts?.totalCount || 0);
  }, [organizationContacts?.totalCount]);

  return (
    <>
      <StickyContainer sx={{ flex: '0 1 auto' }} mb={1}>
        <PageHeaderContainer>
          <PageHeaderContentHost title="Seller Group" name={sellerGroupData?.legalName} />

          <AppActionButtonsContainer>
            {!isCompletedAgreement && !isAgreementInProcess && (
              <AppActionButton
                variant="outlined"
                disabled={!isUpdateOrganization}
                onClick={() => {
                  navigate(`/dashboard/SellerGroup/${id}/Finish`, {
                    state: { step: 1 },
                  });
                }}
              >
                {t('action.finishSetup')}
              </AppActionButton>
            )}
            <AppDeleteButton
              disabled={!isDeleteOrganization && !isDeleteOrganization}
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            />

            <DeleteEntityModal
              entityName={sellerGroupData?.legalName || ''}
              entityType={'Seller Group'}
              onConfirm={deleteSellerGroupHandler}
              open={isDeleteModalOpen}
              setOpen={setIsDeleteModalOpen}
            />
          </AppActionButtonsContainer>
        </PageHeaderContainer>
        {!isSmall ? (
          <BoxStepper>
            <MainStepper connector={null}>
              {steps.map((step, index) => (
                <MainSteps
                  $props={[steps.indexOf(activeStep), index]}
                  onClick={() => {
                    navigateSwitcher[step as unknown as SellerGroupSteps](Number(id));
                  }}
                  key={(Math.random() + 1).toString(36).substring(7)}
                  id={`tab-${step}`}
                >
                  <Label $isactive={activeStep === step ? 'true' : ''}>
                    {step === SellerGroupSteps.Sellers
                      ? `Sellers (${sellers?.totalCount || 0})`
                      : step === SellerGroupSteps.Contacts
                      ? `Contacts (${contactsCount})`
                      : step}
                  </Label>
                </MainSteps>
              ))}
            </MainStepper>
          </BoxStepper>
        ) : (
          <Box
            sx={{
              padding: '6px',
              backgroundColor: SecondaryBackground,
              borderRadius: '10px',
            }}
          >
            <AppAutocomplete
              value={{ label: activeStep, value: activeStep }}
              renderInput={(params) => <AppTextField {...params} size="small" rounded="true" />}
              options={steps.map((step) => ({ label: step, value: step }))}
              size="small"
              onChange={(e, value) => {
                navigateSwitcher[value.value as unknown as SellerGroupSteps](Number(id));
              }}
              disableClearable
              renderOption={(props, option) => {
                if (option.label) {
                  return (
                    <AppListItem {...props} key={JSON.stringify(option)}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: option.label,
                        }}
                      ></span>
                    </AppListItem>
                  );
                }
              }}
            />
          </Box>
        )}
      </StickyContainer>

      {activeStep === SellerGroupSteps.Contacts && (
        <FlexBox sx={{ justifyContent: 'end', width: '100%' }}>
          <AppActionButton
            disabled={!isCreateSellerGroupContact}
            variant="outlined"
            sx={{ maxHeight: '34px', minWidth: '100px !important' }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              navigate(`/dashboard/SellerGroup/${id}/AddContact`);
            }}
            id="add-contact"
          >
            {t('action.add')}
          </AppActionButton>
        </FlexBox>
      )}
      <Outlet
        context={{
          sellerGroupInformation: sellerGroupData,
          refetchSellerGroupData,
          isCompletedAgreement,
          refetchAgreementsList,
          isAgreementInProcess,
          refetchIsAgreementsInProgress,
          setContactsCount,
          contactsCount,
          headerContainerHeight: 200,
        }}
      />
    </>
  );
};

export const ProtectedSellerGroupPage = () => {
  const { t } = useTranslation();
  const isViewSellerPermission = usePermissions(PermissionKeys.ViewSellers);

  if (!isViewSellerPermission) {
    return <NoPermissionMessage message={t('relationships.organisation.view.permission')} />;
  }
  return <ViewSellerGroup />;
};
