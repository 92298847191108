import axios from 'axios';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query';
import {
  SellerInfo,
  AgencyType,
  Lookups,
  PaginationResponse,
  SellerPayload,
  ProviderType,
  ApiError,
  DealershipInfo,
  Pagination,
  UpdateSellerRequestPayload,
  OfacItem,
  SellerTrimmed,
  EditVoidAgreementsType,
  BusinessRoleParams,
  DeleteSellerData,
} from '../types';
import RoleType from '../types/businessRoles';
import {
  Agreement,
  ProductLine,
  SellerAgreementsPayload,
  CommonOrganization,
} from '../types/sellerGroup';
import { useToast } from '../hooks/useToast';
import { OrganizationContact } from '../types/sellerGroupContacts';
import { UpdateDealershipinfoRequestPayload } from '../components/Forms/DealerShipInfoForm/types';
import { useHandleOrganizationErrors } from '../utils/handleOrganizationErrors';
import { Dispatch, SetStateAction } from 'react';

export type DeleteSellerResponse = {
  message: string;
  sellerId: null;
};

export const useAppRolesByType = (
  id: RoleType,
  options?: Omit<UseQueryOptions<BusinessRoleParams[], ApiError>, 'queryKey' | 'queryFn'>,
) => {
  const fetchBusinessRoles = async () => {
    const fetch = await axios.get(`/role/businessRoles/${id}`);
    return fetch?.data;
  };

  const state = useQuery<BusinessRoleParams[], ApiError>(
    ['businessRoles', id],
    fetchBusinessRoles,
    { staleTime: Infinity, ...options },
  );

  return state;
};

export const useSellerAll = (payload: SellerPayload) => {
  const {
    pageNumber,
    name,
    pageSize,
    sortColumn,
    sortDirection,
    agentId,
    status,
    sellerGroupIds,
    customerOrgId,
    advancedSearch,
    isEnabled,
    advancedSearchVisibility,
  } = payload;

  const filterParams = {
    pageNumber: pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    agentId: Number(agentId) || null,
    status: status,
    name,
    phone: advancedSearch?.phone,
    physicalAddress: advancedSearch?.physicalAddress,
    physicalCity: advancedSearch?.physicalCity,
    physicalStateId: advancedSearch?.physicalStateId,
    physicalZipCode: advancedSearch?.physicalZipCode,
    mailingAddress: advancedSearch?.mailingAddress,
    mailingCity: advancedSearch?.mailingCity,
    mailingStateId: advancedSearch?.mailingStateId,
    mailingZipCode: advancedSearch?.mailingZipCode,
    sellerGroupIds: sellerGroupIds,
    customerOrgId: customerOrgId,
    shouldMatchAllCriteria: advancedSearchVisibility,
    legalEntityTypeId: advancedSearch?.legalEntityTypeId,
  };

  const fetchSellers = async () => {
    const fetch = await axios.post<PaginationResponse<SellerInfo>>(`/Sellers/page`, filterParams);
    return fetch.data;
  };

  const state = useQuery<PaginationResponse<SellerInfo>, Error>(
    ['sellers', filterParams],
    fetchSellers,
    {
      enabled: isEnabled,
    },
  );

  return state;
};

export const useAllAgents = (
  options?: Omit<UseQueryOptions<AgencyType[], ApiError>, 'queryKey' | 'queryFn'>,
) => {
  const fetchAgencies = async () => {
    const fetch = await axios.get(`/Agent`);
    return fetch?.data;
  };

  const state = useQuery<AgencyType[], ApiError>('fetchAgencies', fetchAgencies, {
    ...options,
  });

  return state;
};

export const ProviderFetchingQuery = () => {
  const fetchProvider = async () => {
    const fetch = await axios.get(`/common/ProviderInfo`);
    return fetch?.data;
  };

  const state = useQuery<ProviderType, ApiError>('fetchProviderInfo', fetchProvider, {
    staleTime: Infinity,
    retry: true,
  });

  return state;
};

export const FetchLookups = (
  options?: Omit<UseQueryOptions<Lookups, ApiError>, 'queryKey' | 'queryFn'>,
) => {
  const fetchL = async () => {
    const fetch = await axios.get(`/Common/Lookups`);
    return fetch?.data;
  };
  return useQuery<Lookups, ApiError>('fetchLookups', fetchL, { staleTime: Infinity, ...options });
};

const deleteSeller = async (data: DeleteSellerData) => {
  const response = await axios.post(`/Sellers/DeleteSeller`, data);
  return response.data;
};

export const useDeleteSellerQuery = (
  onSuccess?: (data: DeleteSellerResponse) => void,
  onError?: () => void,
) => {
  return useMutation<DeleteSellerResponse, Error, DeleteSellerData>(
    'deleteSellerUpdate',
    deleteSeller,
    {
      onSuccess,
      onError,
    },
  );
};

export const VoidAgreementQuery = (
  onSuccess?:
    | ((
        data: EditVoidAgreementsType,
        variables: { sellerId: number; voidReason: string },
        context: unknown,
      ) => void | Promise<unknown>)
    | undefined,
  onError?:
    | ((
        error: ApiError,
        variables: { sellerId: number; voidReason: string },
        context: unknown,
      ) => void | Promise<unknown>)
    | undefined,
) => {
  const postVoidAgreement = async ({
    sellerId,
    voidReason,
  }: {
    sellerId: number;
    voidReason: string;
  }) => {
    const fetch = await axios.put(`/Sellers/VoidAgreement?sellerId=${sellerId}`, {
      reason: voidReason,
    });
    return fetch?.data;
  };
  return useMutation<EditVoidAgreementsType, ApiError, { sellerId: number; voidReason: string }>(
    'VoidAgreement',
    postVoidAgreement,
    {
      onSuccess,
      onError,
    },
  );
};

// new endpoints from SellerGroup
export const useDealershipInfo = (
  orgId: number,
  options?: Omit<UseQueryOptions<DealershipInfo, ApiError>, 'queryKey' | 'queryFn'>,
) => {
  const req = async () => {
    const res = await axios.get(`/dealership-info/${orgId}`);
    return res.data;
  };

  return useQuery<DealershipInfo, ApiError>('useDealershipInfo', req, options);
};

export const useUpdateDealershipInfoQuery = (
  mutationOptions?:
    | Omit<
        UseMutationOptions<void, ApiError, UpdateDealershipinfoRequestPayload, unknown>,
        'mutationKey' | 'mutationFn'
      >
    | undefined,
) => {
  const putDealershipInfo = async ({ orgId, payload }: UpdateDealershipinfoRequestPayload) => {
    const fetch = await axios.put(`/dealership-info/${orgId}`, payload);
    return fetch?.data;
  };
  return useMutation<void, ApiError, UpdateDealershipinfoRequestPayload>(
    'updateDealershipInfo',
    putDealershipInfo,
    mutationOptions,
  );
};

export const useSellerInfo = (
  sellerId: string | number,
  options?: Omit<UseQueryOptions<CommonOrganization, ApiError>, 'queryKey' | 'queryFn'>,
) => {
  const req = async () => {
    const res = await axios.get(`/Sellers/${sellerId}`);
    return res.data;
  };

  return useQuery<CommonOrganization, ApiError>(['useSellerInfo', sellerId], req, options);
};

export const useSellerAvailableProductLines = (
  sellerId: number,
  agentId?: number,
  options?: Omit<UseQueryOptions<ProductLine[], ApiError>, 'queryKey' | 'queryFn'>,
) => {
  const toast = useToast();
  const fetchProducts = async () => {
    const fetch = await axios.get(`/Sellers/${sellerId}/AvailableProducts`, {
      params: { agentId },
    });
    return fetch?.data;
  };
  return useQuery<ProductLine[], ApiError>(
    ['useSellerAvailableProductLines', sellerId, agentId],
    fetchProducts,
    {
      onError(err) {
        toast.error(err.response.data.message);
      },
      ...options,
    },
  );
};

export const useSellerAgreements = ({ sellerId, payload, options }: SellerAgreementsPayload) => {
  const getAgreements = async () => {
    const res = await axios.post(`Sellers/${sellerId}/agreements`, payload);
    return res.data;
  };
  return useQuery<Pagination<Agreement>, ApiError>(
    ['useSellerAgreements', payload, sellerId],
    getAgreements,
    options,
  );
};

export const useUpdateSellerBusinessInfo = (
  setCustomFieldError: Dispatch<SetStateAction<string>>,
  mutationOptions?: Omit<
    UseMutationOptions<void, ApiError, UpdateSellerRequestPayload, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  const [handleOrgError] = useHandleOrganizationErrors();
  const patchSellerGroup = async ({ id, payload }: UpdateSellerRequestPayload) => {
    const fetch = await axios.patch(`/Sellers/${id}`, payload);
    return fetch?.data;
  };
  return useMutation<void, ApiError, UpdateSellerRequestPayload>(
    'useUpdateSellerBusinessInfo',
    patchSellerGroup,
    {
      ...mutationOptions,
      onError(error) {
        handleOrgError({
          error,
          setCustomFieldError,
        });
      },
    },
  );
};

export const useSellerDelete = (
  mutationOptions?:
    | Omit<UseMutationOptions<void, ApiError, number, unknown>, 'mutationKey' | 'mutationFn'>
    | undefined,
) => {
  const fetchSellerGroup = async (id: number) => {
    const fetch = await axios.delete(`/Sellers/${id}`);
    return fetch?.data;
  };
  return useMutation<void, ApiError, number>('useSellerDelete', fetchSellerGroup, mutationOptions);
};

export const useSellerContactsAll = ({
  sellerId,
  options,
}: {
  sellerId: string | number;
  options?:
    | Omit<UseQueryOptions<OrganizationContact[], ApiError>, 'queryKey' | 'queryFn'>
    | undefined;
}) => {
  const fetchContacts = async () => {
    const res = await axios.get<OrganizationContact[]>(`/Sellers/${sellerId}/contacts`);
    return res.data;
  };
  return useQuery<OrganizationContact[], ApiError>(
    ['useSellerContactsAll', sellerId],
    fetchContacts,
    options,
  );
};
export const useOfacDetails = ({
  agreementId,
  options,
}: {
  agreementId: number | null;
  options?: Omit<UseQueryOptions<OfacItem[], ApiError>, 'queryKey' | 'queryFn'>;
}) => {
  const fetchOfac = async (): Promise<OfacItem[]> => {
    const res = await axios.get(`/ofac/${agreementId}`);
    return res.data;
  };

  const state = useQuery<OfacItem[], ApiError>(
    ['fetchOfacdetail', agreementId],
    fetchOfac,
    options,
  );
  return state;
};

export const useSellerListAll = ({
  sellerGroupId,
  options,
}: {
  sellerGroupId?: number;
  options?: Omit<UseQueryOptions<SellerTrimmed[], ApiError>, 'queryKey' | 'queryFn'>;
}) => {
  const fetchSellersList = async (): Promise<SellerTrimmed[]> => {
    const res = await axios.get(`/Sellers/${sellerGroupId}/list`);
    return res.data;
  };

  const state = useQuery<SellerTrimmed[], ApiError>(
    ['useSellerListAll', sellerGroupId],
    fetchSellersList,
    options,
  );
  return state;
};

export const useSellerSignersList = ({
  sellerId,
  agentId,
  options,
}: {
  sellerId: number;
  agentId: number;
  options?: Omit<UseQueryOptions<OrganizationContact[], ApiError>, 'queryKey' | 'queryFn'>;
}) => {
  const req = async () => {
    const res = await axios.get(`/Sellers/${sellerId}/signers/${agentId}`);
    return res.data;
  };

  const state = useQuery<OrganizationContact[], ApiError>(
    ['useSellerSignersList', sellerId, agentId],
    req,
    options,
  );
  return state;
};
