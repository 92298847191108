import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import CustomTable from '../../../components/shared/CustomTable';
import { t } from 'i18next';
import { Order } from '../../../types/query-params';
import { useParams } from 'react-router-dom';
import { Cell, CellType } from '../../../types/customTable';
import {
  AgreementsAbsoluteContainer,
  AssociatedProductLinesContainer,
} from '../../../components/SellerGroupTabs/ProductLinesTab/styles';
import { OrganizationService } from '../../../services/organizationService';
import { DuplicationFlags, OrgDuplicate } from '../../../types/organization';
import { normalizeZip } from '../../../utils/normalizeZip';
import { normalizeFein } from '../../../utils/normalizeFein';
import { ConditionMark } from './styles';
import { getFormattedNumber } from 'react-phone-hooks';
import { Heading } from '../../../components/shared/AppPageHeaderContentHost/styles';
import { useMediaQuery, useTheme } from '@mui/material';
import { FontSizeLowestMin, FontSizeMed } from '../../../Theme/screensVariables';

interface NewRelationshipTableProps {
  setNewRelOrg: Dispatch<SetStateAction<(OrgDuplicate & { agreementId?: number }) | undefined>>;
}

const NewRelationshipTable: FC<NewRelationshipTableProps> = ({ setNewRelOrg }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const { id } = useParams();

  const [order, setOrder] = useState<Order>(Order.ASC);
  const [orderBy, setOrderBy] = useState<string>('sendOn');

  const { data: newRelations, isFetching } = OrganizationService.useNewRelationshipsForDuplicates({
    orgId: id || '',
    payload: {
      pageNumber: 0,
      pageSize: 100,
      shouldMatchAllCriteria: true,
      sortColumn: orderBy,
      sortDirection: order,
    },
    options: {
      enabled: !!id,
    },
  });

  const tableHeadingsArray = useMemo(() => {
    return [
      { 'Entity Type': '' },
      { 'Entity Name': '' },
      { 'Street Address 1': '' },
      { 'Street Address 2': '' },
      { City: '' },
      { State: '' },
      { ZIP: '' },
      { FEIN: '' },
      { Phone: '' },
    ] as Record<string, string>[];
  }, []);

  const existingRelationsRows = useMemo(() => {
    return newRelations?.data.map<Cell[]>((org) => {
      return [
        {
          data: <ConditionMark $mark={'false'}>{org.entityType}</ConditionMark>,
          type: CellType.Action,
          tableCellProps: {
            style: {
              width: '8%',
            },
          },
        },
        {
          data: <ConditionMark $mark={'false'}>{org.entityName}</ConditionMark>,
          type: CellType.Action,
          tableCellProps: {
            style: {
              width: '22%',
            },
          },
        },
        {
          data: (
            <ConditionMark
              $mark={
                [
                  DuplicationFlags.AddressAndFein,
                  DuplicationFlags.PhoneAndAddress,
                  DuplicationFlags.All,
                  DuplicationFlags.PhysicalAddress,
                ].includes(org.duplicationFlags)
                  ? 'true'
                  : 'false'
              }
            >
              {org.streetAddress1}
            </ConditionMark>
          ),
          type: CellType.Action,
          tableCellProps: {
            style: {
              width: '15%',
            },
          },
        },
        {
          data: (
            <ConditionMark
              $mark={
                [
                  DuplicationFlags.AddressAndFein,
                  DuplicationFlags.PhoneAndAddress,
                  DuplicationFlags.All,
                  DuplicationFlags.PhysicalAddress,
                ].includes(org.duplicationFlags)
                  ? 'true'
                  : 'false'
              }
            >
              {org.streetAddress2}
            </ConditionMark>
          ),
          type: CellType.Action,
          tableCellProps: {
            style: {
              width: '8%',
            },
          },
        },
        {
          data: (
            <ConditionMark
              $mark={
                [
                  DuplicationFlags.AddressAndFein,
                  DuplicationFlags.PhoneAndAddress,
                  DuplicationFlags.All,
                  DuplicationFlags.PhysicalAddress,
                ].includes(org.duplicationFlags)
                  ? 'true'
                  : 'false'
              }
            >
              {org.city}
            </ConditionMark>
          ),
          type: CellType.Action,
          tableCellProps: {
            style: {
              width: '8%',
            },
          },
        },
        {
          data: (
            <ConditionMark
              $mark={
                [
                  DuplicationFlags.AddressAndFein,
                  DuplicationFlags.PhoneAndAddress,
                  DuplicationFlags.All,
                  DuplicationFlags.PhysicalAddress,
                ].includes(org.duplicationFlags)
                  ? 'true'
                  : 'false'
              }
            >
              {org.state}
            </ConditionMark>
          ),
          type: CellType.Action,
          tableCellProps: {
            style: {
              width: '8%',
            },
          },
        },
        {
          data: (
            <ConditionMark
              $mark={
                [
                  DuplicationFlags.AddressAndFein,
                  DuplicationFlags.PhoneAndAddress,
                  DuplicationFlags.All,
                  DuplicationFlags.PhysicalAddress,
                ].includes(org.duplicationFlags)
                  ? 'true'
                  : 'false'
              }
            >
              {normalizeZip(org.zip || '')}
            </ConditionMark>
          ),
          type: CellType.Action,
          tableCellProps: {
            style: {
              width: '8%',
            },
          },
        },
        {
          data: (
            <ConditionMark
              $mark={
                [
                  DuplicationFlags.AddressAndFein,
                  DuplicationFlags.Fein,
                  DuplicationFlags.All,
                ].includes(org.duplicationFlags)
                  ? 'true'
                  : 'false'
              }
            >
              {normalizeFein(org.fein || '')}
            </ConditionMark>
          ),
          type: CellType.Action,
          tableCellProps: {
            style: {
              width: '8%',
            },
          },
        },
        {
          data: (
            <ConditionMark
              $mark={
                [
                  DuplicationFlags.PhoneAndAddress,
                  DuplicationFlags.PhoneAndFein,
                  DuplicationFlags.All,
                  DuplicationFlags.PhoneNumber,
                ].includes(org.duplicationFlags)
                  ? 'true'
                  : 'false'
              }
            >
              {getFormattedNumber(org.phoneNumberCountryCode.replace('+', '') + org.phoneNumber) +
                (org.phoneNumberExt ? ` x ${org.phoneNumberExt}` : '')}
            </ConditionMark>
          ),
          type: CellType.Action,
          tableCellProps: {
            style: {
              width: '15%',
            },
          },
        },
      ];
    });
  }, [newRelations?.data]);

  useEffect(() => {
    setNewRelOrg(newRelations?.data[0]);
  }, [newRelations?.data, setNewRelOrg]);

  return (
    <AssociatedProductLinesContainer maxHeight={'100% !important'}>
      <AgreementsAbsoluteContainer>
        <Heading flexGrow={0} fontSize={isSmall ? FontSizeLowestMin : FontSizeMed} mt={1}>
          {'New Relationship Request'}
        </Heading>
        <CustomTable
          displayOptions={{ paginationTop: false, paginationBottom: false, tableOnly: true }}
          page={0}
          rowsPerPage={0}
          data={existingRelationsRows || []}
          valueRef={undefined}
          goError={false}
          handlePageChange={() => {}}
          handleRowsPerPageChange={() => {}}
          tableHeadingsArray={tableHeadingsArray}
          advancedSearchVisibility={true}
          setAdvancedSearchVisibility={() => {}}
          setPagination={() => {}}
          onSubmitAdvancedSearch={() => {}}
          onResetAdvancedSearch={() => {}}
          searchValue={''}
          handleSearchValue={() => {}}
          setPage={() => {}}
          tableTitle={''}
          placeHolder={t('action.search')}
          addNewLink={{ link: '', state: undefined }}
          order={order}
          orderBy={orderBy}
          handleRequestSort={(newOrder, newOrderBy) => {
            setOrder(newOrder);
            setOrderBy(newOrderBy);
          }}
          InputsComponent={() => <></>}
          isDataLoading={isFetching}
          total={0}
          openAdvancedSearch={() => {}}
          isAdvanceSearch={false}
        />
      </AgreementsAbsoluteContainer>
    </AssociatedProductLinesContainer>
  );
};

export default NewRelationshipTable;
