import axios from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ApiError, ApplicationResponse } from '../types';

export const FileService = {
  useScanFile: (
    options?: Omit<
      UseMutationOptions<
        ApplicationResponse<boolean>,
        ApiError,
        { payload: FormData; incomingFiles: any[] }
      >,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const req = async ({ payload }: { payload: FormData; incomingFiles: any[] }) => {
      const res = await axios.post('/file/scan', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data;
    };
    return useMutation<
      ApplicationResponse<boolean>,
      ApiError,
      { payload: FormData; incomingFiles: any[] }
    >(['useScanFile'], req, options);
  },
};
