import { ApiError, PayloadAdvanceSearch, StateForAutocomplete } from './api';
import { Order, OrganizationType, Status } from './query-params';
import { Dayjs } from 'dayjs';
import { UseQueryOptions } from 'react-query';
import { AgreementStatus, Gates } from './agreements';
import { Pagination } from './agent';
import { FeinSource } from './organization';
import { Organizations } from '../services/sellerGroupServices';
import { AccountOwner } from './accountOwner';
import { PhoneFormType } from './user';
import { IModificationLog } from '../components/ModificationLog';
import { OrganizationContact } from './sellerGroupContacts';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FileWithPath } from 'react-dropzone/.';

export type SellerGroupInfo = {
  sellerGroupId: number;
  sellerGroupName: string;
  phoneNumber: string | null;
  phoneNumberExt: string | null;
  phoneNumberCountryCode: string | null;
  zipCode: string;
  stateId: number;
  city: string;
  sellerCount: number;
  status: string;
  agency: Agency;
  customerOrgCode: string | null;
};

export interface Agency {
  sellerId: number;
  sellerName: string;
  agencyId: number;
  agencyName: string;
}

export interface SellerGroupParams {
  name: string;
  fein: string;
  organizationType: string;
  doingBusinessAs: string;
  fax: string;
  phone: string;
  physicalAddress1: string;
  physicalAddress2: string;
  physicalCity: string;
  physicalStateId?: number;
  physicalZipCode: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingStateId?: number;
  mailingZipCode: string;
  status?: string;
  agencyId: number;
}

export interface SellerGroupPayload extends PayloadAdvanceSearch<SellerGroupParams> {
  status?: Status;
  agencyId?: number | null;
  shouldMatchAllCriteria: boolean;
  phone: string | undefined;
}

export type AdvancedSearchPayloadSellerGroup = {
  name: string;
  fein: string;
  organizationType: string;
  doingBusinessAs: string;
  fax: string;
  phone: string;
  physicalAddress1: string;
  physicalAddress2: string;
  physicalCity: string;
  physicalStateId?: number;
  physicalZipCode: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingStateId?: number;
  mailingZipCode: string;
  status?: string;
  agencyId: number;
};

export const initialSellerGroupSearchValues = {
  pageNumber: 1,
  pageSize: 10,
  sortColumn: 'sellerGroupName',
  sortDirection: 'asc',
  status: '',
  name: '',
  phone: '',
  physicalLocationAddress: '',
  physicalLocationCity: '',
  physicalLocationZip: '',
  mailingAddress: '',
  mailingCity: '',
  mailingZip: '',
};
export type ContactFormValues = {
  contactId?: number;
  orgId?: number;
  contactOwner: FormSingleSelectValue | null;
  firstName: string;
  lastName: string;
  phone: PhoneFormType;
  email: string;
  title: string;
  isActive?: boolean;
  contactClassification: StateForAutocomplete[];
  onwiseSystemRole: StateForAutocomplete[];
  associatedSellers: { label: string; value: number }[];
};

export type FormSingleSelectValue = {
  label: string;
  value: number;
};

export type AddProductLineFormValues = {
  productLines: number[];
  legacyProductLines: number[];
  legacyProductLinesFlag: boolean;
  signer: FormSingleSelectValue | null;
  message: string;
  agent: FormSingleSelectValue | null;
  agentRelationshipOwner: FormSingleSelectValue | null;
  effectiveDate: Dayjs;
};

export type AttachagreementFormValues = {
  productLines: number[];
  legacyProductLines: number[];
  legacyProductLinesFlag: boolean;
  signer: FormSingleSelectValue | null;
  agent: FormSingleSelectValue | null;
  agentRelationshipOwner: FormSingleSelectValue | null;
  tenantSigner: FormSingleSelectValue | null;
  effectiveDate: Dayjs;
  attachedFiles: FileWithPath[] | null;
};

export type PreviewAgrementPayload = {
  productIds: string[];
  message?: string;
  providerOrgId: number;
  redirectUrl?: string;
  orgId: number;
  contactId: number;
  effectiveDate: string;
  agencyContactId: number;
};

export type ContactRole = {
  roleId: number;
  roleName: string;
  description: string;
};

export interface ContactReqObj {
  payload: {
    orgId: number;
    orgType: number;
    contacts: Contact[];
    contactManagementOrgId: number | null;
  };
  additionalInfo: {
    contactOwnerName: string;
  };
}

export interface ContactPayload {
  payload: {
    orgId: number;
    orgType: number;
    contact: Contact;
    contactManagementOrgId: number | null;
  };
  additionalInfo: {
    contactOwnerName: string;
  };
}
export interface ContactResponse {
  contact: Contact;
  message: string;
  contacts: number[];
}

export interface Contact {
  businessRoles: ContactRole[] | undefined;
  contactId?: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  phoneNumberExt: string | null;
  phoneNumberCountryCode: string | null;
  signer?: boolean;
  status: boolean;
  otherRole?: string;
  title: string;
  onWiseSystemRoles: ContactRole[] | undefined;
  fax?: string;
  sellerIds?: number[] | null;
  contactManagementOrg?: { id: number };
}

export interface ContactResponse {
  orgId: number;
  contacts: number[];
  message: string;
}

export type ContactFormProps = {
  contactData: OrganizationContact | null;
  contactStatus: boolean;
  setContactStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  businessRolesForContacts: ContactRole[] | undefined;
  onwiseRoles: ContactRole[] | undefined;
  onHandleResponseSubmit: (formValues: ContactFormValues) => void;
  cancelPopUp: boolean;
  setCancelPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setIsUnsavedData: React.Dispatch<React.SetStateAction<boolean>>;
  isUnsavedData: boolean;
  isSaveEnabled: boolean;
  setIsSaveEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  isEdit: boolean;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  customOrgId?: string;
  customOrganization?: Organizations | string;
  setShowContactsTable?: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface CommonOrganization extends IModificationLog {
  sellerGroupId?: number;
  sellerGroupName?: string;
  customerOrgId?: number | null;
  customerOrgCode?: string | null;
  legalName?: string;
  orgName?: string;
  businessName?: string;
  legalEntityTypeId: number;
  doingBusinessAs: string;
  fein: string;
  orgId?: number;
  status?: boolean;
  addresses: Address[];
  agentId?: string;
  sellerId?: number;
  feinSource?: FeinSource;
  accountOwner?: AccountOwner | null;
}
export interface Address {
  addressId: number;
  addressTypeId: number;
  streetAddressLine1: string;
  streetAddressLine2: string;
  city: string;
  stateId: number;
  zipCode: string;
  mainFax: string;
  website: string;
  sameAsAddressTypeId: number;
  phoneNumber: string | null;
  phoneNumberExt: string | null;
  phoneNumberCountryCode: string | null;
  googlePlaceId: string | null;
}

export interface ProductLine {
  productId: number;
  productName: string;
  hasAttachedLineAddendum: boolean;
  hasProductLineAddendum: boolean;
  isLegacyAgreementProduct: boolean;
}
export interface SellerGroupAddress {
  addressId?: number | null;
  addressType?: number;
  sameAsAddressTypeId?: number | null;
  streetAddressLine1?: string;
  streetAddressLine2?: string | null;
  city?: string;
  country?: string;
  stateId?: number;
  zipCode?: string;
  googlePlaceId?: string;
}
export interface UpdateSellerGroupPayload {
  legalName: string;
  legalId: string;
  legalEntityTypeId: number;
  organizationType: number;
  doingBusinessAs: string | null;
  addresses: SellerGroupAddress[];
  phoneNumber: string | null;
  phoneNumberExt: string | null;
  phoneNumberCountryCode: string | null;
  websiteURL: string | null;
  fax: string;
}

export interface UpdateSellerGroupRequestPayload {
  id: number;
  payload: Partial<UpdateSellerGroupPayload>;
}

export type AgreementsPayload = {
  sellerGroupId: number;
  payload: {
    pageNumber?: number;
    pageSize?: number;
    sortColumn?: string;
    sortDirection?: string;
    shouldMatchAllCriteria?: boolean;
  };
  options?: Omit<UseQueryOptions<Pagination<Agreement>, ApiError>, 'queryKey' | 'queryFn'>;
};
export type SellerAgreementsPayload = {
  sellerId: number;
  payload: {
    pageNumber: number;
    pageSize: number;
    sortColumn?: string;
    sortDirection?: string;
    shouldMatchAllCriteria: boolean;
  };
  options?: Omit<UseQueryOptions<Pagination<Agreement>, ApiError>, 'queryKey' | 'queryFn'>;
};

export type AssociatedProductLinesPayload = {
  sellerGroupId: number;
  sortColumn: string | null;
  sortDirection: Order | null;
  options?: Omit<UseQueryOptions<Array<AssociatedProductLine>, ApiError>, 'queryKey' | 'queryFn'>;
};

export type AssociatedProductLinesSellerPayload = {
  sellerId: number;
  sortColumn: string | null;
  sortDirection: Order | null;
  options?: Omit<UseQueryOptions<Array<AssociatedProductLine>, ApiError>, 'queryKey' | 'queryFn'>;
};

export type AgreementProductLine = {
  productId: number;
  productName: string;
  description: string;
  status: boolean;
  addressStates: unknown[];
  documents: unknown[];
};

export type Agreement = {
  agreementId: number;
  agreementType: string;
  productLines: Array<AgreementProductLine>;
  effectiveDate: string;
  signer: null | { id: number; name: string };
  sendBy: {
    id: number;
    name: string;
  } | null;
  sendOn: string;
  status: AgreementStatus;
  envelopeId: string;
  statusReasonMessage: string | null;
  declinedBy: { id: number; firstName: string; lastName: string; email: string } | null;
  deliveryFailureEmail: string | null;
  agent: {
    id: number;
    name: string;
  } | null;
  agentContact: {
    id: number;
    name: string;
  } | null;
  ofacStatus: boolean;
  signingParty: {
    id: number;
    name: string;
    orgType: OrganizationType.Seller | OrganizationType.SellerGroup;
  } | null;
  voidedBy: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  reviewedBy: {
    id: number;
    name: string;
  } | null;
  agreementStatusReason: {
    agreementStatusReason: string;
    agreementStatusReasonId: number;
  } | null;
  reviewType: Gates | null;
  reviewedOn: string | null;
};

export type AssociatedProductLine = {
  productLineId: number;
  productLineName: string;
  effectiveDate: string;
  signer?: {
    id: number;
    name: string;
  } | null;
  status: AgreementStatus;
  agreementId: number;
  statusReasonMessage: string | null;
  declinedBy: { id: number; firstName: string; lastName: string; email: string } | null;
  deliveryFailureEmail: string | null;
  envelopeId: string;
  agent?: {
    id: number;
    name: string;
  } | null;
  agentContact?: {
    id: number;
    name: string;
  } | null;
  signingParty?: {
    id: number;
    name: string;
    orgType: OrganizationType.Seller | OrganizationType.SellerGroup;
  } | null;
  agreementStatusReason: {
    agreementStatusReasonId: number;
    agreementStatusReason: string;
  } | null;
  reviewType: Gates | null;
  reviewedBy: {
    id: number;
    name: string;
  } | null;
  reviewedOn: string | null;
};

export type SellerGroupSmallItem = {
  id: number;
  name: string;
  phoneNumber: string;
  phoneNumberExt: string;
  phoneNumberCountryCode: string;
  fein: string;
  legalEntityTypeId: number;
};
