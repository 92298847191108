import { BaseTextFieldProps, OutlinedInputProps, TextField } from '@mui/material';
import { FC, forwardRef } from 'react';
import styled from 'styled-components';
import {
  White,
  Platinum,
  MainTextColor,
  SecondaryTextColor,
  RedOrange,
} from '../../../Theme/colorsVariables';

export const StyledAppTextField = styled(TextField)<{ $rounded: string }>`
  fieldset {
    border: none;
    border-radius: ${(props) => (props.$rounded === 'true' ? '4px' : 'unset')};
    span {
      font-size: 17px;
    }
  }

  div {
    background: ${(props) => (props.disabled ? Platinum : White)};
    border-radius: ${(props) => (props.$rounded === 'true' ? '4px' : 'unset')};

    input,
    textarea {
      font-size: 16px;
      font-family: Gotham-Medium;
    }
    input[disabled],
    textarea[disabled] {
      background: ${Platinum};
      -webkit-text-fill-color: ${MainTextColor} !important;
    }
  }

  div {
    &.MuiInputBase-root.Mui-error {
      fieldset {
        border: 1px solid ${RedOrange} !important;
      }
    }
  }
  .MuiFormLabel-root.Mui-error {
    color: ${RedOrange} !important;
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    font-family: Gotham-Book;
    font-size: 16px;
    color: ${SecondaryTextColor};
  }
  .MuiInputLabel-shrink,
  .MuiInputLabel-shrink.Mui-focused {
    font-size: 20px !important;
    top: -2px;
  }
  .MuiInputBase-root {
    height: 100%;
  }
  @media only screen and (max-width: 1500px) {
    .MuiAutocomplete-clearIndicator {
      width: 18px;
      height: 18px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    fieldset {
      span {
        font-size: 14px;
      }
    }

    div {
      input,
      textarea {
        font-size: 12px;
      }
    }

    .MuiFormLabel-root,
    .MuiFormLabel-root.Mui-focused {
      font-size: 12px;
    }
    .MuiInputLabel-shrink,
    .MuiInputLabel-shrink.Mui-focused {
      font-size: 16px !important;
      top: 0px;
      left: -2px;
    }
    .MuiFormHelperText-root {
      margin-top: 0;
      font-size: 10px;
    }
    .MuiAutocomplete-endAdornment {
      top: calc(50% - 14px);
    }
  }
`;

export interface AppTextFieldProps extends BaseTextFieldProps {
  errorMessage?: string;
  onChange?: OutlinedInputProps['onChange'];
  InputProps?: Partial<OutlinedInputProps>;
  variant?: 'standard' | 'outlined' | 'filled';
  rounded?: 'true' | 'false';
}

export const AppTextField: FC<AppTextFieldProps> = forwardRef((props, ref) => {
  const { errorMessage, rounded, ...otherProps } = props;
  return (
    <StyledAppTextField
      {...otherProps}
      data-cy={`input-${props.label}`}
      ref={ref}
      label={errorMessage ? `${props.label} - ${errorMessage}` : props.label}
      error={!!errorMessage || props.error}
      fullWidth
      $rounded={rounded ? rounded : 'false'}
    />
  );
});
