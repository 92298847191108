import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { FontSizeMax, FontSizeMin } from '../../../Theme/screensVariables';
import { FlexBox, SpaceBetweenBox } from '../FlexBox';
import { LightSlateGray } from '../../../Theme/colorsVariables';
import { breakPointMedium } from '../../../utils';
export const PageHeaderContainer = styled(SpaceBetweenBox)`
  margin-bottom: 12px;
  margin-top: 4px;
  flex-wrap: wrap;
`;

export const PageHeaderNameContainer = styled(FlexBox)<{ $title?: string; $name?: string }>`
  align-items: center;
  gap: ${(props) => (!props.$title && props.$name ? '0px !important' : '10px !important')};
`;

export const PageHeading = styled(Box)<{ $color: string }>`
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${breakPointMedium('height: 20px;')}
  span {
    font-size: 16px;
    line-height: 16px;
    font-family: Gotham-Medium;
    color: ${({ $color: color }) => (color ? color : `${LightSlateGray}`)};
    ${breakPointMedium('font-size: 14px;')}
  }
`;

export const Title = styled(Box)`
  font-size: 24px;
  font-family: Gotham-Medium;
  color: #000000;
  margin-top: 0;
  ${breakPointMedium('font-size: 18px;')}
`;
export const Heading = styled(Box)`
  font-family: Gotham-MonoSpace-Numbers, sans-serif;
  color: #000000;
  font-weight: 600;
  ${breakPointMedium('font-size: 14px;')}
`;

export const ActiveText = styled(Typography)<{ $active: string }>`
  font-size: ${FontSizeMax};
  font-family: Gotham-Medium;
  color: ${({ $active: active }) => (active === 'true' ? '#09BD3C' : '#FD5353')};
  span,
  p {
    margin: 0;
    color: ${({ $active: active }) => (active === 'true' ? '#09BD3C' : '#FD5353')};
  }
  ${breakPointMedium(`font-size: ${FontSizeMin};`)}
`;
