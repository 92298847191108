import { Box, Step, StepLabel, Stepper } from '@mui/material';
import styled from 'styled-components';
import {
  FlashWhite,
  HoverColor,
  LightSlateGray,
  MainColor,
  ThirdBackground,
  White,
} from '../colorsVariables';
import { breakPointSmall } from '../../utils/MediaBreakPoint';

export const MainStepper = styled(Stepper)`
  height: max-content;
  width: 100%;
  border: 4px solid ${White};
  border-radius: 12px;
  justify-content: space-between;
  .MuiStepConnector-root {
    display: none;
  }
  overflow-x: auto;
  overflow-y: hidden;
`;

export const BoxStyledMainMedia = `
  font-size: 14px;
`;

export const Arrow = styled.div<{ $len: number; $active: number; $position: number }>`
  position: absolute;
  right: 6px;
  z-index: 1;
  border: solid ${White};
  display: ${(props) => (props.$len === props.$position ? 'none' : 'inline-block')};
  padding: 9px;
  height: 39px;
  width: 39px;
  background: ${(props) => {
    let style = '';
    if (props.$active === props.$position) {
      style = `${MainColor} !important`;
    } else if (props.$active > props.$position) {
      style = `${HoverColor} !important`;
    } else {
      style = `${FlashWhite}!important`;
    }
    return style;
  }};
  border-width: 0 4px 4px 0;
  transform: translateY(38.5px) rotate(-45deg) skew(-11deg, -11deg) translateX(46px);
`;

export const MainSteps = styled(Step)<{ $props: number[] }>`
  height: 58px;
  background: ${(props) => {
    let style = '';
    if (props.$props[0] === props.$props[1]) {
      style = `${MainColor}`;
    } else if (props.$props[0] > props.$props[1]) {
      style = `${HoverColor} !important`;
    } else {
      style = `${FlashWhite}`;
    }
    return style;
  }};
  display: flex;
  flex-grow: 2;
  justify-content: center;
  align-items: center;
  &&:first-child {
    margin-left: 0px;
    border-radius: 12px 0px 0px 12px;
  }
  &&:last-child {
    border-radius: 0px 12px 12px 0px;
  }
  position: relative;
`;

export const Lable = styled(StepLabel)<{ $active: string; $position: string }>`
  & .MuiStepLabel-label {
    color: ${(props) => {
      let style = '';
      if (props.$active === props.$position) {
        style = `${White} !important`;
      } else if (props.$active > props.$position) {
        style = `${White} !important`;
      } else {
        style = `${LightSlateGray} !important`;
      }
      return style;
    }};

    font-size: 18px;
    font-family: Gotham-Bold;
    word-break: break-word;
    ${breakPointSmall(`
        font-size: 12px;
      `)}
  }
`;

export const BoxStyledMain = styled(Box)`
  ${breakPointSmall(BoxStyledMainMedia)}
`;

export const StickyContainer = styled(Box)`
  position: sticky;
  z-index: 100;
  padding: 0 0 8px;
  top: 0;
  background: ${ThirdBackground};
`;
