import { TableCell, Box, TableRow, TableCellProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CellType,
  Cell,
  CustomTableRowsProps,
  DocuSignTableData,
} from '../../../types/customTable';
import { AddTemplateLabel, DeleteTemplateLabel } from '../../../pages/AgreementTemplateList/style';
import { ChangePassButton } from '../../PermissionSets/styles';
import { AppLink } from '../CustomTableAddButton/styles';
import { CellLinkTo, CellText } from './types';
import AddDocumentIcon from '../../assets/AddDocumentIcon';
import DownloadIcon from '../../assets/DownloadIcon';
import EditDocumentIcon from '../../assets/EditDocumentIcon';
import RemoveDocumentIcon from '../../assets/RemoveDocumentIcon';
import ViewIcon from '../../assets/ViewIcon';
import AppTooltip from '../AppTooltip';

export default function CustomTableRows({ columns }: CustomTableRowsProps) {
  const { t } = useTranslation();

  const renderLinkCell = (
    key: string,
    linkTo: CellLinkTo,
    text?: CellText,
    styles?: React.CSSProperties,
    otherProps?: TableCellProps,
  ): JSX.Element => (
    <TableCell key={key} {...otherProps}>
      <AppLink to={`${linkTo}`}>
        <Box style={styles} data-cy={'table-link'}>
          {text}
        </Box>
      </AppLink>
    </TableCell>
  );

  const renderInfoCell = (
    key: string,
    text?: CellText,
    styles?: React.CSSProperties,
    otherProps?: TableCellProps,
  ) => (
    <TableCell key={key} style={styles} {...otherProps}>
      {text}
    </TableCell>
  );

  const renderStatusCell = (
    key: string,
    text?: CellText,
    defaultStatus?: string,
    styles?: React.CSSProperties,
    otherProps?: TableCellProps,
  ) => (
    <TableCell key={key} style={styles} {...otherProps}>
      {text === defaultStatus ? (
        <Box color="success.light">{text}</Box>
      ) : (
        <Box color="error.light">{text}</Box>
      )}
    </TableCell>
  );

  const renderDocSign = (key: string, data: DocuSignTableData, otherProps?: TableCellProps) => {
    if (
      data.agreement.templateId &&
      (data.viewAgreement || data.editAgreement || data.deleteAgreement || data.downloadAgreement)
    ) {
      return (
        <TableCell key={key} {...otherProps}>
          {data.viewAgreement && (
            <AppTooltip title={t('action.viewAgreementTemplate')}>
              <DeleteTemplateLabel
                onClick={() => {
                  data.callbackOnView();
                }}
              >
                <ViewIcon />
              </DeleteTemplateLabel>
            </AppTooltip>
          )}
          {data.editAgreement && (
            <AppTooltip title={t('action.editAgreementTemplate')}>
              <DeleteTemplateLabel
                onClick={() => {
                  data.callbackOnEdit();
                }}
              >
                <EditDocumentIcon />
              </DeleteTemplateLabel>
            </AppTooltip>
          )}
          {data.deleteAgreement && (
            <AppTooltip title={t('action.deleteAgreementTemplate')}>
              <DeleteTemplateLabel
                onClick={() => {
                  data.callbackOnDelete();
                }}
              >
                <RemoveDocumentIcon />
              </DeleteTemplateLabel>
            </AppTooltip>
          )}
          {data.downloadAgreement && (
            <AppTooltip title={t('action.downloadAgreementTemplate')}>
              <DeleteTemplateLabel
                onClick={() => {
                  data.callbackOnDownload();
                }}
              >
                <DownloadIcon />
              </DeleteTemplateLabel>
            </AppTooltip>
          )}
        </TableCell>
      );
    } else {
      return (
        <TableCell key={key} {...otherProps}>
          {data.addAgreement ? (
            <AppTooltip title={'Add Template'}>
              <DeleteTemplateLabel
                onClick={() => {
                  data.callbackOnAdd();
                }}
              >
                <AddDocumentIcon />
              </DeleteTemplateLabel>
            </AppTooltip>
          ) : (
            <AppTooltip title={t(data.title)}>
              <AddTemplateLabel $active={'false'}>No Possible Actions</AddTemplateLabel>
            </AppTooltip>
          )}
        </TableCell>
      );
    }
  };

  const renderPassword = (key: string, data: any, otherProps?: TableCellProps) => (
    <TableCell key={key} {...otherProps}>
      <ChangePassButton
        onClick={() => data.callbackPassword()}
        disabled={
          data.rawUserData.status === 1 &&
          data.rawUserData.authUserId !== '' &&
          data.rawUserData.isUserVerified
            ? false
            : true
        }
        data-cy={`user-table-changePass-${data.rawUserData?.firstName}`}
        variant="outlined"
      >
        {t('action.changePassword')}
      </ChangePassButton>
    </TableCell>
  );

  const renderActionCell = (
    key: string,
    data: any,
    styles?: React.CSSProperties,
    otherProps?: TableCellProps,
  ) => {
    return (
      <TableCell key={key} style={styles} {...otherProps}>
        {data}
      </TableCell>
    );
  };

  const CellTypeRenderer: {
    [k in CellType]: (cell: Cell) => JSX.Element;
  } = {
    [CellType.Info]: (cell: Cell) =>
      renderInfoCell(
        (Math.random() + 1).toString(36).substring(7),
        cell.data as CellText | undefined,
        cell.styles,
        cell.tableCellProps,
      ),
    [CellType.Link]: (cell: Cell) =>
      renderLinkCell(
        (Math.random() + 1).toString(36).substring(7),
        cell.linkTo,
        cell.data as CellText | undefined,
        cell.styles,
        cell.tableCellProps,
      ),
    [CellType.StatusFilter]: (cell: Cell) =>
      renderStatusCell(
        (Math.random() + 1).toString(36).substring(7),
        cell.data as CellText | undefined,
        cell.defaultStatus,
        cell.styles,
        cell.tableCellProps,
      ),
    [CellType.DocuSign]: (cell: Cell) =>
      renderDocSign(
        (Math.random() + 1).toString(36).substring(7),
        cell.data as DocuSignTableData,
        cell.tableCellProps,
      ),
    [CellType.Password]: (cell: Cell) =>
      renderPassword((Math.random() + 1).toString(36).substring(7), cell.data, cell.tableCellProps),
    [CellType.Action]: (cell: Cell) =>
      renderActionCell(
        (Math.random() + 1).toString(36).substring(7),
        cell.data,
        cell.styles,
        cell.tableCellProps,
      ),
  };

  return (
    <>
      {columns?.map((item) => (
        <TableRow key={`${(Math.random() + 1).toString(36).substring(7)}`}>
          {item.length && item.map((cell) => CellTypeRenderer[cell.type](cell))}
        </TableRow>
      ))}
    </>
  );
}
